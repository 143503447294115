import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import PageTitle from "../../components/pageTitle";
import StepsComponent from "./components/stepsComponent";
import SVGIcon from "../../components/svgIcon";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {collectionFiles} from "../../actions";
import axios from "axios";
import refreshToken from "../../api/refreshToken";
import LoaderComponent from "./components/loader";
import ModalDeleteCollectionTrack from "./components/modalForDeleteTrack";
import ModalLeavePage from "./components/modalForLeave";
import {url} from "../../api/api";

const steps = [
      {
            name: 'General ',
            active: false,
      },
      {
            name: 'Songs upload',
            active: false,
      },
      {
            name: 'Songs edit',
            active: true,
      }
];

class List extends React.Component {
      render() {
            const {provided, innerRef, children} = this.props;
            return (
                <div {...provided.droppableProps} ref={innerRef}>
                      {children}
                </div>
            );
      }
}

class Track extends React.Component {
      str_pad_left = (time) => {
            let minutes = Math.floor(time / 60);
            let seconds = time - minutes * 60;
            if ( minutes < 10) {
                  minutes = '0' + minutes
            }
            if ( seconds < 10) {
                  seconds = '0' + seconds
            }
            return `${minutes}:${seconds}`;
      };

      render() {
            const {provided, innerRef, track, index} = this.props;
            return (
                <div
                    className='list-track-line'
                    {...provided.draggableProps}

                    ref={innerRef}
                >
                      <div
                          className='#'>{index + 1}</div>
                      <div className=''>{track.title}</div>
                      <div className=''>{track.image ?
                          <img
                              src={track.image.path}
                              alt=''
                              className='track-image'/> : 'No image'}</div>
                      <div className=''>{track.hasLyrics ? <SVGIcon name='success' width='16' height='16'
                                                                    fill='#03BAAE'/> : 'No lyrics'}</div>
                      <div className=''>{this.str_pad_left(track.duration)}</div>
                      <div className=''>
                            <div onClick={() => this.props.deleteTrack(track.id)}><SVGIcon
                                name='trash'
                                width='14'
                                height='18'
                                className='icon'/></div>
                      </div>
                      <div className=''  {...provided.dragHandleProps}><SVGIcon
                          name='drag'
                          width='11'
                          height='5'
                          className='icon'/></div>
                </div>
            );
      }
}

class MusicManagementStep3 extends Component {
      constructor(props) {
            super(props);

            this.state = {
                  loading: true,
                  modal: false,
                  modalLeave: false,
                  collection: {},

                  formError: '',
            };
      }

      UNSAFE_componentWillMount() {
            if (this.props.music.activeCollectionId === 0 && this.props.music.mode === 'edit') {
                  this.props.history.push('/music-management');
            }
      }

      componentDidMount() {
            this.getData()
      }

      getData = () => {
            let token = localStorage.getItem('accessToken');
            let path = `${url}/collections/${this.props.music.activeCollectionId}`;

            axios.get(path,
                {
                      headers: {
                            Authorization: 'Bearer ' + token,
                      },
                })
                .then(response => {
                      // console.log(response.data.collection.songs);
                      this.setState({
                            loading: false,
                            collection: response.data.collection,
                            songs: response.data.collection.songs,
                            formError: '',
                      });
                })
                .catch(error => {
                      // console.log(JSON.stringify(error));
                      this.setState({
                            loading: false,
                            formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                      });
                      if (error.response.status === 401) {
                            refreshToken(this.getData);
                      }
                });
      };

      onDragEnd = (result: any) => {
            const {destination, draggableId} = result;
            if (!destination) {
                  return
            }
            this.setState({
                  loading: true
            });
            const activeCollectionId = this.props.music.activeCollectionId;

            let body = {}
            body['id'] = this.state.songs[draggableId].id;
            if (draggableId > destination.index && destination.index !== 0) {
                  body['predecessorId'] = this.state.songs[destination.index - 1].id;
            } else if (draggableId < destination.index && destination.index !== 0) {
                  body['predecessorId'] = this.state.songs[destination.index].id;
            }

            let token = localStorage.getItem('accessToken');
            axios.post(`${url}/collections/${activeCollectionId}/changeSongPosition`,
                body, {
                      headers: {
                            Authorization: 'Bearer ' + token,
                      },
                })
                .then(response => {
                      // console.log(response.data);
                      this.getData();
                })
                .catch(error => {
                      // console.log(JSON.stringify(error.response));
                      this.setState({
                            loading: false,
                            formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                      });
                });
      };

      deleteTrack = () => {
            this.setState({
                  loading: true,
            });

            let token = localStorage.getItem('accessToken');
            axios.delete(`${url}/songs/${this.state.idForDelete}`,
                {
                      headers: {
                            Authorization: 'Bearer ' + token,
                      },
                })
                .then(response => {
                      // console.log(response);
                      this.setState({
                            idForDelete: 0,
                            modal: false,
                            loading: true,
                            formError: '',
                      });
                      this.getData();
                })
                .catch(error => {
                      // console.log(JSON.stringify(error.response));
                      this.setState({
                            loading: false,
                            formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                      });
                      if (error.response.status === 401) {
                            refreshToken(this.deleteTrack());
                      }
                });

      };

      render() {
            const {activeCollectionId, collectionTitle} = this.props.music;
            const {songs, loading} = this.state;
            let collectionName = collectionTitle.length > 20 ? (collectionTitle).substring(0, 20) + '...' : collectionTitle;
            return (
                <div className='page-container'>
                      <PageTitle
                          title={this.props.music.mode === 'edit' ? `Edit collection - ${collectionName}` : `New collection - ${collectionName}`}
                          backButton={true} backFunction={() => this.setState({modalLeave: true, fromHeader: true,})} history={this.props.history} path='/music-management'/>

                      <div className='content-block import-page'>
                            <StepsComponent steps={steps}/>
                            {this.state.loading ? <LoaderComponent loading={this.state.loading}/> :
                                <DragDropContext onDragEnd={this.onDragEnd}>
                                      <div className='tracks-container'>
                                            <div className='list-titles-line'>
                                                  <div className='#'>#</div>
                                                  <div className=''>song title</div>
                                                  <div className=''>Image</div>
                                                  <div className=''>Lyrics</div>
                                                  <div className=''>duration</div>
                                                  <div className=''>actions</div>
                                                  <div className=''>drag</div>
                                            </div>
                                            <Droppable droppableId={'1'}>
                                                  {provided => (
                                                      <List provided={provided} innerRef={provided.innerRef}>
                                                            {songs.map((track, i) => {

                                                                  return <Draggable
                                                                      key={i}
                                                                      draggableId={i.toString()}
                                                                      index={i}>
                                                                        {
                                                                              provided => (
                                                                                  <Track provided={provided}
                                                                                         counter={i}
                                                                                         index={i}
                                                                                         track={track}
                                                                                         innerRef={provided.innerRef}
                                                                                         deleteTrack={(id) => {
                                                                                               this.setState({
                                                                                                     modal: true,
                                                                                                     idForDelete: id,
                                                                                               })
                                                                                         }}
                                                                                  />
                                                                              )
                                                                        }

                                                                  </Draggable>
                                                            })
                                                            }
                                                            {provided.placeholder}
                                                      </List>
                                                  )}
                                            </Droppable>
                                      </div>
                                </DragDropContext>
                            }
                            <p className={this.state.formError !== '' ? 'form-error' : 'form-error hide'}>
                                  <SVGIcon name="error" width='16'
                                           fill='#ED1566'/><span>{this.state.formError}</span></p>
                            <div className='content-footer two-buttons'>
                                  <button className='fourth-button'
                                          onClick={() => {
                                                this.setState({
                                                      modalLeave: true,
                                                })
                                          }}>Back
                                  </button>
                                  <button className='first-button'
                                          onClick={() => {
                                                this.props.music.mode === 'edit' ? this.props.history.push(`/music-management/${activeCollectionId}/success`) : this.props.history.push('/music-management/new-collection/success')
                                          }}>Finish
                                  </button>
                            </div>
                            {this.state.modalLeave && <ModalLeavePage
                                leave={() => this.state.fromHeader ? this.props.history.push('/music-management') : this.props.history.goBack()}
                                no={() => {
                                      this.setState({modalLeave: false, fromHeader: false})
                                }}/>}
                            {this.state.modal && <ModalDeleteCollectionTrack loading={loading}
                                                                             deleteTrack={this.deleteTrack}
                                                                             closeModal={() => this.setState({modal: false})}/>}
                      </div>
                </div>
            );
      }
}

const mapStateToProps = state => {
      return {
            files: state.files,
            music: state.music,
      };
};

export default withRouter(connect(mapStateToProps, {collectionFiles})(MusicManagementStep3));
