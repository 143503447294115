import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import PageTitle from "../../components/pageTitle";
import {collectionFiles, collectionMode} from "../../actions";
import SVGIcon from '../../components/svgIcon';

class NotificationsManagementSuccess extends Component {
    componentDidMount() {
        setTimeout( () => {this.props.history.push('/notifications-management')},5000)
    }
      render() {
          let {notificationTitle} = this.props.notification;
          let notificationName = notificationTitle.length > 20 ? (notificationTitle).substring(0, 20) + '...' : notificationTitle;

          return (
                <div className='page-container'>
                    <PageTitle title={this.props.notification.mode === 'edit' ? `Edit notification - ${notificationName}` : `New notification - ${notificationName}`} backButton={false} history={this.props.history}/>
                    <div className='content-block import-page success-page'>
                        <div className='content-title'>
                            Success
                        </div>
                        <p className='success-text'>{this.props.notification.mode === 'edit' ? 'All the changes in the notification were successfully uploaded' : 'The notification was successfully created'}</p>
                        <SVGIcon name='success' width='56' height='56' fill='#03BAAE'/>
                    </div>
                </div>
            );
      }
}

const mapStateToProps = state => {
      return {
          notification: state.notification,
      };
};

export default withRouter(connect(mapStateToProps, {collectionMode, collectionFiles})(NotificationsManagementSuccess));

