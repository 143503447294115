import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PageTitle from "../../components/pageTitle";
import { importFiles } from '../../actions';
import {connect} from "react-redux";
import { v4 as generateUuid } from 'uuid'
import SVGIcon from '../../components/svgIcon'
import axios from 'axios'
import LoaderComponent from '../musicManagement/components/loader'
import refreshToken from '../../api/refreshToken'
import { url } from '../../api/api'

class ImportDataIndex extends Component {
  state = {
  }
  constructor(props) {
    super(props)
    this.state.sessionId = generateUuid()
  }

  onChange = (data) => {
    this.props.importFiles(data, this.state.sessionId)
  }

  sendDataFiles = () => {
    this.setState({
      loading: true,
    })

    const body = new FormData()
    this.props.files.files.forEach((file) => {
      body.append('files', file)
    })
    let token = localStorage.getItem('accessToken')

    axios
      .post(`${url}/import/${this.state.sessionId}`, body, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        this.setState({
          formError: '',
          loading: false,
        })
            this.props.history.push('/import-data/uploading')

      })
      .catch((error) => {
        this.setState({
          loading: false,
          formError: error.response.data.message
            ? error.response.data.message
            : 'Looks like something went wrong. Please try again later.',
        })
        if (error.response.status === 401) {
          refreshToken(this.sendDataFiles)
        }
      })
  }

  render() {
    return (
      <div className="page-container">
        <PageTitle
          title="Import data"
          backButton={false}
          history={this.props.history}
        />

        <div className="content-block import-page">
          <div className="content-title">Upload files</div>
          <p
            className={
              this.state.formError ? 'form-error' : 'form-error hide'
            }>
            <SVGIcon name="error" width="16" height="16" fill="#ED1566" />
            <span>{this.state.formError}</span>
          </p>
          <div className="content-wrapper">
            <div className="files-area">
              {this.props.files.files.length > 0 ? (
                this.props.files.files.map((item, i) => {
                  return (
                    <div key={i} className="file-wrapper">
                      <SVGIcon
                        name="emptyFile"
                        width="20"
                        height="25"
                        fill="#666666"
                      />
                      <span className="file-name">{item.name}</span>
                    </div>
                  )
                })
              ) : (
                  <span>Please, choose files first</span>
                )}
            </div>
            <LoaderComponent loading={this.state.loading} />
            <div className="files-button">
              <label htmlFor="single-file">
                <div className="third-button">Choose files to upload</div>
              </label>
              <input
                type="file"
                id="single-file"
                multiple
                accept=".csv"
                onChange={(e) => {
                  this.onChange([...e.target.files])
                }}
              />
            </div>
            {this.props.files.files.length > 0
              && <button
                className={
                  this.state.loading
                    ? 'first-button disabled'
                    : 'first-button'
                }
                onClick={this.sendDataFiles}>
                Upload
            </button>
            }
          </div>
        </div>
      </div>
    )
  }

  componentWillUnmount() {
    importFiles([], null)
  }
}

const mapStateToProps = state => {
      return {
            files: state.files
      };
};

export default withRouter(connect(mapStateToProps, {importFiles})(ImportDataIndex));
