import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PageTitle from "../../components/pageTitle";
import SVGIcon from "../../components/svgIcon";

class ImportDataSuccess extends Component {
      componentDidMount() {
            setTimeout( () => {this.props.history.push('/import-data')},2000)
      }

      render() {
            return (
                <div className='page-container'>
                      <PageTitle title='Import data' backButton={true} history={this.props.history} path='/import-data'/>
                      <div className='content-block import-page success-page'>
                            <div className='content-title'>
                                  Success
                            </div>
                            <p className='success-text'>An importing operation has been completed</p>
                            <SVGIcon name='success' width='56' height='56' fill='#03BAAE'/>
                      </div>
                </div>


            );
      }
}

export default withRouter(ImportDataSuccess);
