import React, {useState} from 'react';

export default function UploadBigImage({
                                             value,
                                             onUpload
                                       }) {

      const [width, setWidth] = useState(0);
      const [error, setError] = useState('');
      const imgInput = React.createRef();

      function onImgLoad({target: img}) {
            setError('');
            setWidth(img.offsetWidth);
            if (img.offsetWidth !== img.offsetHeight) {
                  setError('The image should be square.')
            }
      }

      function imageRender() {
            if (value !== null && value !== undefined) {
                  if (typeof value === "object" && !value.path) {
                        return <img onLoad={onImgLoad} src={URL.createObjectURL(value)} alt=''
                                    className={width > 0 && error === '' ? 'big-image' : 'big-image hide'}/>
                  } else if (typeof value === 'string'){
                        return <img onLoad={onImgLoad} src={value} alt=''
                                    className='big-image'/>
                  } else {
                        return <img onLoad={onImgLoad} src={value.path} alt=''
                                    className='big-image'/>
                  }
            }
      }

      function cleanImage() {
            imgInput.current.value = '';
      }

      return <div className='UploadBigImage'>
            <p className='input-label'>Image</p>
            <div className='image-wrapper'>
                  <div className='image-placeholder'>
                        {error && <span>{error}</span>}
                  </div>
                  {imageRender()}
            </div>
            <span className='attention'>The image should be square.</span>
            <div className='files-button'>
                  <label htmlFor='single-file'>
                        <div className='second-button'>
                              Upload
                        </div>
                  </label>
                  <input type='file' id='single-file' ref={imgInput} accept="image/*" onChange={e => {
                        onUpload(e);
                        cleanImage();
                  }}/>
            </div>
      </div>
}
