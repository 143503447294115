import React, {Component} from 'react';

import Pagination from "../../../components/pagination";
import SVGIcon from "../../../components/svgIcon";
import axios from "axios";
import LoaderComponent from "../../musicManagement/components/loader";
import {url} from "../../../api/api";

class StudentsCollection extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  opened: false,
                  students: [],
                  loading: false,

                  total: 0,
                  perPage: 0,
                  page: 1,
            }
      }

      // shouldComponentUpdate(nextProps, nextState) {
      //       if (nextProps.search !== this.props.search) {
      //             this.setState({
      //                   loading: true,
      //             });
      //             this.getData(nextProps.search);
      //             return true
      //       }
      //
      //       if (nextState.opened !== this.state.opened || nextState.students !== this.state.students) {
      //             return true
      //       }
      //
      //       return false
      // }

      componentDidMount() {
            this.getData();
      }

      getData() {
            this.setState({
                  loading: true,
            });

            let token = localStorage.getItem('accessToken');
            let path = `${url}/students?semester=${this.props.item.id}&include=account,studentSemesters`;
            if (this.state.page > 1) {
                  path += '&page=' + this.state.page
            }
            if (this.props.search) {
                  path += '&term=' + this.props.search;
            }


            axios.get(path,
                {
                      headers: {
                            Authorization: 'Bearer ' + token,
                      },
                })
                .then(response => {
                      // console.log(response.data);
                      this.setState({
                            loading: false,
                            students: response.data.students,
                            total: response.data.meta.total,
                            perPage: response.data.meta.perPage,
                            formError: '',
                      });
                      if (this.props.search && response.data.students.length === 0) {
                            this.setState({
                                  opened: false,
                            })
                      } else if ((this.props.search && response.data.students.length > 0) || this.state.opened === true) {
                            this.setState({
                                  opened: true,
                            })
                      } else {
                            this.setState({
                                  opened: false,
                            })
                      }
                })
                .catch(error => {
                      // console.log(error);
                });
      }

      onChangePage = (page) => {
            this.setState(state => {
                  return {
                        ...state,
                        page: page,
                  }
            }, this.getData)
      };

      renderStatus = (item) => {
            let status = '';
            item.studentSemesters.forEach((semester, j) => {
                  if (this.props.item.id === semester.semesterId) {
                        status = semester.enrollmentStatus;
                  }
            });
            return <td>{status}</td>
      };

      render() {
            const {name, collectionsCount} = this.props.item;
            const {opened, students} = this.state;

            if (this.props.search === '' || students.length > 0) {
                  return (
                      <div className={opened ? 'collection-block' : 'collection-block closed'}>
                            <div className='students-collection-header'>
                                  <div>
                                        <p className='collection-name-title'>Collection/semester assigned</p>
                                        <div className='name-line'>
                                              <div
                                                  onClick={() => this.setState((prevState) => ({opened: !prevState.opened}))}
                                                  className='open-line-link'>
                                                    {opened ? <SVGIcon name='chevron-top' width='10' fill='#000'/> :
                                                        <SVGIcon name='chevron-bottom' width='10' fill='#000'/>}
                                              </div>
                                              <p className='collection-name'>{name}</p>
                                        </div>
                                  </div>
                                  {collectionsCount === 0 && <div className='icon-block'><SVGIcon name="warn" width='16' height='16'
                                                                          fill='#2D3E56'/><p>No collections assigned</p></div>}
                            </div>
                            <div className='collection-body'>
                                  {this.state.loading ?
                                      <div style={{textAlign: 'center'}}><LoaderComponent loading={this.state.loading}/>
                                      </div> :
                                      <table className='collection-table'>
                                            <thead>
                                            <tr>
                                                  <td>parent name</td>
                                                  <td>email</td>
                                                  <td>contacts</td>
                                                  <td>account id</td>
                                                  <td>student name</td>
                                                  <td>date of birth</td>
                                                  <td>status</td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {students.map((item, i) => {
                                                      return <tr key={i}>
                                                            <td>{item.account.firstName && item.account.lastName ? item.account.firstName + ' ' + item.account.lastName : '-'}</td>
                                                            <td>{item.account.email ? item.account.email : '-'}</td>
                                                            <td>{item.account.phone ? item.account.phone : '-'}</td>
                                                            <td>{item.accountId ? item.accountId : '-'}</td>
                                                            <td>{item.firstName && item.lastName ? item.firstName + ' ' + item.lastName : '-'}</td>
                                                            <td>{item.birthday ? item.birthday : '-'}</td>
                                                            {this.renderStatus(item)}
                                                      </tr>
                                                }
                                            )}
                                            </tbody>
                                      </table>
                                  }
                            </div>
                            <div className='collection-footer'>
                                  <Pagination total={this.state.total}
                                              perPage={this.state.perPage}
                                              page={this.state.page}
                                              onChangePage={this.onChangePage}
                                  />
                            </div>
                      </div>
                  )
            } else {
                  return null;
            }
      }
}

export default StudentsCollection;
