import {AUTHORIZE} from "../actions/types";

const defaultState = {
    user: 'USER',
};

export default function layout(state = defaultState, action) {
    switch (action.type) {
        case AUTHORIZE:
            return {
                user: action.user
            };
        default:
            return state
    }
}
