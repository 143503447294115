// <SVGIcon name="phone" width='100' fill='red' />

import React from "react";
import PropTypes from 'prop-types';

const getViewBox = name => {
      switch (name) {
            case "chevron-left":
                  return "0 0 7 12";
            case "chevron-right":
                  return "0 0 8 12";
            case "chevron-top":
                  return "0 0 12 8";
            case "chevron-bottom":
                  return "0 0 12 8";
            case "error":
                  return "0 0 511.76 511.76";
            case "eye":
                  return "0 0 16 10";
            case "close-eye":
                  return "0 0 16 14";
            case "music":
                  return "0 0 15 16";
            case "students":
                  return "0 0 18 14";
            case "notifications":
                  return "0 0 14 17";
            case "import":
                  return "0 0 14 15";
            case "exit":
                  return "0 0 24 24";
            case "success":
                  return "0 0 16 16";
            case "search":
                  return "0 0 18 18";
            case "emptyFile":
                  return "0 0 26 32";
            case "edit":
                  return "0 0 19 19";
            case "trash":
                  return "0 0 14 18";
            case "camera":
                  return "0 0 24 24";
            case "drag":
                  return "0 0 11 5";
            case "warn":
                  return "0 0 16 16";
            case "archived":
                  return "0 0 18 18";
            case "info":
                  return "0 0 16 16";
            default:
                  return "0 0 32 32";
      }
};

const getPath = (name, props) => {
      switch (name) {
            case "chevron-left":
                  return (
                      <path {...props}
                            d="M6.70615 2.11538C7.095 1.72569 7.09466 1.09466 6.70538 0.705384C6.31581 0.315811 5.68419 0.315811 5.29462 0.705385L0 6L5.29462 11.2946C5.68419 11.6842 6.31581 11.6842 6.70538 11.2946C7.09466 10.9053 7.095 10.2743 6.70615 9.88462L2.83 6L6.70615 2.11538Z"/>
                  );
            case "chevron-right":
                  return (
                      <path {...props}
                            d="M0.704616 11.2946C0.315343 10.9053 0.314999 10.2743 0.703847 9.88462L4.58 6L0.703847 2.11538C0.314999 1.72569 0.315343 1.09466 0.704616 0.705384C1.09419 0.315811 1.72581 0.315811 2.11538 0.705385L7.41 6L2.11538 11.2946C1.72581 11.6842 1.09419 11.6842 0.704616 11.2946Z"/>
                  );
            case "chevron-top":
                  return (
                      <path {...props} d="M1.41 7.41L6 2.83L10.59 7.41L12 6L6 -2.62268e-07L-2.62268e-07 6L1.41 7.41Z"/>
                  );
            case "chevron-bottom":
                  return (
                      <path xmlns="http://www.w3.org/2000/svg"
                            d="M10.59 0.589512L6 5.16951L1.41 0.589512L7.15493e-08 1.99951L6 7.99951L12 1.99951L10.59 0.589512Z"/>
                  );
            case "error":
                  return (
                      <path {...props}
                            d="M436.896,74.869c-99.84-99.819-262.208-99.819-362.048,0c-99.797,99.819-99.797,262.229,0,362.048    c49.92,49.899,115.477,74.837,181.035,74.837s131.093-24.939,181.013-74.837C536.715,337.099,536.715,174.688,436.896,74.869z     M361.461,331.317c8.341,8.341,8.341,21.824,0,30.165c-4.16,4.16-9.621,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251    l-75.413-75.435l-75.392,75.413c-4.181,4.16-9.643,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251    c-8.341-8.341-8.341-21.845,0-30.165l75.392-75.413l-75.413-75.413c-8.341-8.341-8.341-21.845,0-30.165    c8.32-8.341,21.824-8.341,30.165,0l75.413,75.413l75.413-75.413c8.341-8.341,21.824-8.341,30.165,0    c8.341,8.32,8.341,21.824,0,30.165l-75.413,75.413L361.461,331.317z"/>
                  );
            case "eye":
                  return (
                      <path {...props}
                            d="M5.60209 5.00105C5.60209 3.67509 6.67761 2.60019 8.00435 2.60019C9.33107 2.60019 10.4066 3.67509 10.4066 5.00105C10.4066 6.32702 9.33107 7.40191 8.00435 7.40191C6.67761 7.40192 5.60209 6.32701 5.60209 5.00105ZM8 10C9.37367 9.99364 10.7975 9.64479 12.1454 8.9769C13.1462 8.46057 14.1215 7.7321 14.9681 6.83295C15.3839 6.37397 15.9143 5.70937 16 5.00027C15.9899 4.38604 15.3583 3.62788 14.9681 3.16758C14.1742 2.30375 13.2243 1.59561 12.1454 1.0231C10.8884 0.386754 9.49717 0.0203772 8 0C6.62503 0.00645351 5.20149 0.359325 3.85512 1.0231C2.85435 1.53943 1.87855 2.26843 1.03192 3.16758C0.61612 3.62656 0.0857592 4.29118 0 5.00027C0.0101328 5.61451 0.641733 6.37269 1.03192 6.83298C1.82583 7.69681 2.77624 8.4044 3.85512 8.97692C5.11141 9.61279 6.50611 9.97665 8 10ZM8.00101 8.75865C5.91943 8.75865 4.23201 7.07574 4.23201 4.99973C4.23201 2.92373 5.91943 1.24082 8.00101 1.24082C10.0826 1.24082 11.77 2.92374 11.77 4.99973C11.77 7.07574 10.0826 8.75865 8.00101 8.75865Z"/>
                  );
            case "close-eye":
                  return (
                      <path {...props}
                            d="M8.9297 2.51902C8.62449 2.48504 8.31437 2.46556 8.00001 2.46072C6.62634 2.46699 5.20236 2.81094 3.8545 3.46999C2.85372 3.97949 1.87885 4.69877 1.03223 5.58602C0.616427 6.03895 0.0857467 6.69475 0 7.39445C0.0101333 8.00057 0.64204 8.74869 1.03223 9.2029C1.82613 10.0553 2.77562 10.754 3.8545 11.3189C3.89116 11.3373 3.92796 11.3554 3.96484 11.3732L2.96388 13.1726L4.32395 14L11.6762 0.823606L10.367 0L8.9297 2.51902ZM12.0342 3.41772L11.0352 5.19999C11.4948 5.81462 11.7676 6.57257 11.7676 7.39445C11.7676 9.44299 10.0806 11.1038 7.99902 11.1038C7.90903 11.1038 7.82182 11.0938 7.73339 11.0877L7.07226 12.2659C7.37717 12.2995 7.68546 12.324 7.99999 12.3282C9.37497 12.3218 10.7981 11.9739 12.1445 11.3189C13.1453 10.8094 14.1211 10.0901 14.9678 9.2029C15.3836 8.74999 15.9142 8.09417 16 7.39445C15.9899 6.78834 15.358 6.04022 14.9678 5.58601C14.1739 4.73361 13.2234 4.03491 12.1445 3.46997C12.1081 3.45179 12.0708 3.43545 12.0342 3.41772ZM7.99903 3.68512C8.09031 3.68512 8.18087 3.68888 8.27051 3.69516L7.4961 5.07536C6.40924 5.30218 5.59375 6.25406 5.59375 7.39346C5.59375 7.67968 5.64496 7.9537 5.73926 8.20769C5.73936 8.20798 5.73915 8.20842 5.73926 8.20871L4.96288 9.59292C4.5022 8.97773 4.23046 8.21729 4.23046 7.39443C4.23047 5.34592 5.91744 3.6851 7.99903 3.68512ZM10.252 6.59529L8.50685 9.70753C9.58786 9.47644 10.3975 8.5289 10.3975 7.39346C10.3975 7.11246 10.343 6.8453 10.252 6.59529Z"/>
                  );
            case "music":
                  return (
                      <path {...props}
                            d="M14.2921 0.324771C14.1999 0.066591 13.9417 -0.0624992 13.6836 0.029708L4.57348 3.09099C4.38906 3.14631 4.24153 3.33073 4.24153 3.55202V12.1826C3.79894 11.8691 3.22725 11.6847 2.60024 11.6847C1.89947 11.6847 1.27246 11.906 0.811424 12.2748C0.313505 12.6621 0 13.2153 0 13.8239C0.0184414 14.4509 0.313505 15.0042 0.811424 15.4099C1.27246 15.7787 1.91791 16 2.60024 16C3.30102 16 3.92803 15.7787 4.38906 15.4099C4.88698 15.0226 5.20049 14.4694 5.20049 13.8608C5.20049 13.8424 5.20049 13.8424 5.20049 13.8239V7.79356L13.3332 5.04578V9.1029C12.8906 8.80784 12.3189 8.62342 11.7103 8.62342C11.0095 8.62342 10.3825 8.84472 9.9215 9.21355C9.42358 9.60082 9.11007 10.1541 9.11007 10.7626C9.11007 11.3712 9.42358 11.9244 9.9215 12.3117C10.3825 12.6805 11.028 12.9018 11.7103 12.9018C12.4111 12.9018 13.0381 12.6805 13.4991 12.3117C13.9971 11.9244 14.3106 11.3712 14.3106 10.7626V0.472303C14.3106 0.416978 14.3106 0.361654 14.2921 0.324771ZM3.79894 14.6538C3.50387 14.8935 3.07972 15.041 2.60024 15.041C2.12077 15.041 1.69661 14.8935 1.40155 14.6538C1.12493 14.4325 0.958955 14.1559 0.958955 13.8424C0.977397 13.5288 1.14337 13.2522 1.40155 13.0309C1.69661 12.7912 2.12077 12.6437 2.60024 12.6437C3.07972 12.6437 3.50387 12.7912 3.79894 13.0309C4.07556 13.2522 4.24153 13.5288 4.24153 13.8424C4.24153 14.1559 4.07556 14.4325 3.79894 14.6538ZM5.20049 6.77928V3.88397L13.3332 1.13619V4.0315L5.20049 6.77928ZM12.909 11.5925C12.6139 11.8322 12.1898 11.9798 11.7103 11.9798C11.2308 11.9798 10.8067 11.8322 10.5116 11.5925C10.235 11.3712 10.069 11.0946 10.069 10.7811C10.069 10.4676 10.235 10.1909 10.5116 9.96965C10.8067 9.72991 11.2308 9.58238 11.7103 9.58238C12.1898 9.58238 12.6139 9.72991 12.909 9.96965C13.1856 10.1909 13.3516 10.4676 13.3516 10.7811C13.3332 11.0946 13.1672 11.3712 12.909 11.5925Z"/>
                  );
            case "students":
                  return (
                      <path {...props}
                            d="M17.9995 4.55944C17.972 4.31278 17.8751 3.95604 17.3518 3.70428L10.2583 0.239333C9.62072 -0.0796928 8.54236 -0.0796928 7.90634 0.238824L0.648754 3.70428C0.0631936 3.98509 0 4.404 0 4.57065C0 4.76838 0.0840883 5.14347 0.648754 5.43701L1.18233 5.69488V8.82501C0.801642 9.00236 0.550396 9.38967 0.550396 9.8096C0.550396 10.2102 0.788902 10.5965 1.14003 10.7815L0.207418 13.7439H3.05419L2.12208 10.783C2.4778 10.6041 2.71121 10.2244 2.71121 9.8096C2.71121 9.38254 2.46557 9.00338 2.07928 8.82501V6.14081L2.95583 6.57093V10.7473C2.95583 10.8289 2.98233 10.9094 3.03431 10.9874L3.04043 10.996C3.044 11.0001 3.0496 11.0072 3.05725 11.0164C3.56127 11.6565 5.48001 13.7439 8.98981 13.7439C12.6214 13.7439 14.5585 11.4644 14.9101 11.0072L14.9249 10.9874C14.9769 10.9094 15.0034 10.8289 15.0034 10.7473V6.59131L17.3507 5.43701C17.9368 5.15621 18 4.7373 18 4.57065L17.9995 4.55944ZM8.98981 12.9081C5.89994 12.9081 4.19626 11.0536 3.83239 10.6092V6.95417L7.90685 8.90298C8.22537 9.05179 8.64989 9.1369 9.07135 9.1369C9.52797 9.1369 9.95045 9.04567 10.2578 8.88208L14.1472 6.97659V10.6092C13.7834 11.0536 12.0797 12.9081 8.98981 12.9081ZM16.9762 4.68327L14.5503 5.8758C14.4326 5.88243 14.3358 5.9339 14.2787 6.02155L9.88012 8.14975C9.4816 8.3587 8.67945 8.35819 8.259 8.14822L3.13369 5.69081L9.04179 4.99822C9.15747 4.98548 9.25991 4.9284 9.33126 4.8382C9.39904 4.75258 9.43012 4.64301 9.41738 4.53905C9.40464 4.42337 9.34757 4.32093 9.25787 4.24958C9.17174 4.1818 9.06217 4.15071 8.9577 4.16346L1.72967 5.01657L1.01976 4.68124C0.945866 4.64862 0.901529 4.61295 0.876557 4.58746C0.901529 4.56504 0.946886 4.53293 1.02282 4.4993L8.27786 1.01447C8.49292 0.916627 8.78239 0.860568 9.07135 0.860568C9.38324 0.860568 9.69411 0.919175 9.88267 1.01346L16.9802 4.48044C17.0669 4.51867 17.1087 4.55281 17.128 4.57421C17.1 4.60377 17.0511 4.64556 16.9762 4.68327Z"/>
                  );
            case "notifications":
                  return (
                      <path {...props}
                            d="M13.1739 13.5287C13.1239 13.4963 12.7478 13.2328 12.3712 12.4358C11.6797 10.9721 11.5345 8.91026 11.5345 7.43833C11.5345 7.43192 11.5344 7.42558 11.5341 7.41921C11.5266 5.47387 10.3617 3.79691 8.6948 3.04449V1.89377C8.6948 0.849535 7.84666 0 6.80415 0H6.64746C5.60495 0 4.75681 0.849535 4.75681 1.89377V3.04443C3.0843 3.79927 1.91711 5.48479 1.91711 7.43833C1.91711 8.91026 1.77195 10.9721 1.08036 12.4358C0.703802 13.2328 0.32771 13.4962 0.277706 13.5287C0.0671319 13.6262 -0.0366944 13.8513 0.011749 14.0794C0.0606572 14.3098 0.276013 14.4687 0.511191 14.4687H4.16177C4.18216 15.8679 5.32415 17 6.72582 17C8.12749 17 9.26948 15.8679 9.28986 14.4687H12.9404C13.1756 14.4687 13.391 14.3098 13.4399 14.0794C13.4883 13.8513 13.3845 13.6261 13.1739 13.5287ZM5.75293 1.89377C5.75293 1.39968 6.15423 0.997721 6.64749 0.997721H6.80418C7.29744 0.997721 7.69874 1.39968 7.69874 1.89377V2.7211C7.38437 2.65612 7.05895 2.62192 6.72569 2.62192C6.39253 2.62192 6.0672 2.65608 5.75297 2.721V1.89377H5.75293ZM6.72582 16.0023C5.8734 16.0023 5.17809 15.3177 5.15787 14.4687H8.29374C8.27355 15.3177 7.57824 16.0023 6.72582 16.0023ZM8.73129 13.4709C8.73119 13.4709 1.64624 13.4709 1.64624 13.4709C1.7324 13.3366 1.82009 13.1846 1.90725 13.0128C2.57476 11.6965 2.91324 9.82099 2.91324 7.43833C2.91324 5.33269 4.6235 3.61964 6.72565 3.61964C8.82781 3.61964 10.5381 5.33269 10.5381 7.43999C10.5381 7.44613 10.5382 7.45224 10.5384 7.45835C10.5403 9.83145 10.8787 11.7002 11.5444 13.0128C11.6315 13.1846 11.7193 13.3366 11.8054 13.4709H8.73129Z"/>
                  );
            case "import":
                  return (
                      <g>
                            <path {...props}
                                  d="M12.3846 4.54318H9.69232C9.39492 4.54318 9.15384 4.76919 9.15384 5.048C9.15384 5.32681 9.39492 5.55282 9.69232 5.55282H12.3846C12.682 5.55282 12.9231 5.77883 12.9231 6.05764V12.6202C12.9231 12.899 12.682 13.125 12.3846 13.125H1.61539C1.31799 13.125 1.07691 12.899 1.07691 12.6202V6.05761C1.07691 5.7788 1.31799 5.55279 1.61539 5.55279H4.30769C4.60508 5.55279 4.84616 5.32678 4.84616 5.04797C4.84616 4.76916 4.60508 4.54315 4.30769 4.54315H1.61539C0.723232 4.54315 0 5.22118 0 6.05758V12.6201C0 13.4565 0.723232 14.1345 1.61539 14.1345H12.3846C13.2768 14.1345 14 13.4565 14 12.6201V6.05761C14 5.22121 13.2768 4.54318 12.3846 4.54318Z"/>
                            <path {...props}
                                  d="M10.0728 2.67185L7.38049 0.147812C7.17024 -0.0492706 6.82936 -0.0492706 6.61911 0.147812L3.92678 2.67185C3.72019 2.87239 3.72612 3.19196 3.94003 3.38564C4.14871 3.57459 4.47951 3.57459 4.68819 3.38564L6.46136 1.72329V10.0961C6.46136 10.3749 6.70244 10.6009 6.99983 10.6009C7.29723 10.6009 7.5383 10.3749 7.5383 10.0961V1.72332L9.31147 3.38567C9.52539 3.57935 9.86626 3.57379 10.0729 3.37325C10.2743 3.17761 10.2743 2.86748 10.0728 2.67185Z"/>
                      </g>);
            case "exit":
                  return (
                      <g>
                            <path {...props}
                                  d="M15.75 17.625V12.75H8.95312C8.75421 12.75 8.56345 12.671 8.4228 12.5303C8.28214 12.3897 8.20312 12.1989 8.20312 12C8.20312 11.8011 8.28214 11.6103 8.4228 11.4697C8.56345 11.329 8.75421 11.25 8.95312 11.25H15.75V6.375C15.7493 5.67904 15.4725 5.01179 14.9803 4.51967C14.4882 4.02755 13.821 3.75074 13.125 3.75H4.125C3.42904 3.75074 2.76179 4.02755 2.26967 4.51967C1.77755 5.01179 1.50074 5.67904 1.5 6.375V17.625C1.50074 18.321 1.77755 18.9882 2.26967 19.4803C2.76179 19.9725 3.42904 20.2493 4.125 20.25H13.125C13.821 20.2493 14.4882 19.9725 14.9803 19.4803C15.4725 18.9882 15.7493 18.321 15.75 17.625Z"/>
                            <path {...props}
                                  d="M19.9392 12.7501L17.4698 15.2199C17.3351 15.3617 17.2611 15.5505 17.2636 15.7461C17.2661 15.9417 17.3449 16.1285 17.4832 16.2668C17.6215 16.4051 17.8084 16.4839 18.004 16.4864C18.1995 16.4889 18.3884 16.415 18.5302 16.2802L22.2802 12.5302C22.4207 12.3896 22.4997 12.1989 22.4997 12.0001C22.4997 11.8013 22.4207 11.6106 22.2802 11.4699L18.5302 7.71994C18.3884 7.58522 18.1995 7.51123 18.004 7.51373C17.8084 7.51624 17.6215 7.59504 17.4832 7.73334C17.3449 7.87164 17.2661 8.0585 17.2636 8.25407C17.2611 8.44964 17.3351 8.63845 17.4698 8.78025L19.9392 11.2501H15.75V12.7501H19.9392Z"/>
                      </g>);
            case "success":
                  return (
                      <path {...props}
                            d="M8 0C3.58 0 0 3.58 0 8C0 12.42 3.58 16 8 16C12.42 16 16 12.42 16 8C16 3.58 12.42 0 8 0ZM7.385 12.66H6.045L2.805 8.12L4.146 6.87L6.715 9.27L11.856 3.339L13.196 4.279L7.385 12.66Z"/>
                  );
            case "search":
                  return (
                      <path {...props}
                            d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"/>
                  );
            case "emptyFile":
                  return (
                      <path {...props}
                            d="M16 0L25.6 9.6V28.8C25.6 29.6487 25.2629 30.4626 24.6627 31.0627C24.0626 31.6629 23.2487 32 22.4 32H3.2C2.35131 32 1.53737 31.6629 0.937258 31.0627C0.337142 30.4626 0 29.6487 0 28.8V3.2C0 2.35131 0.337142 1.53737 0.937258 0.937258C1.53737 0.337142 2.35131 0 3.2 0H16ZM22.4 28.8V11.2H14.4V3.2H3.2V28.8H22.4Z"/>
                  );
            case "edit":
                  return (
                      <path {...props}
                            d="M0 15.2501V19.0001H3.75L14.81 7.94006L11.06 4.19006L0 15.2501ZM17.71 5.04006C18.1 4.65006 18.1 4.02006 17.71 3.63006L15.37 1.29006C14.98 0.900059 14.35 0.900059 13.96 1.29006L12.13 3.12006L15.88 6.87006L17.71 5.04006Z"/>
                  );
            case "trash":
                  return (
                      <path {...props}
                            d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z"/>);
            case "camera":
                  return (
                      <g>
                            <path  {...props}
                                   d="M3 8C3 8.55 3.45 9 4 9C4.55 9 5 8.55 5 8V6H7C7.55 6 8 5.55 8 5C8 4.45 7.55 4 7 4H5V2C5 1.45 4.55 1 4 1C3.45 1 3 1.45 3 2V4H1C0.45 4 0 4.45 0 5C0 5.55 0.45 6 1 6H3V8Z"/>
                            <path  {...props}
                                   d="M13 17C14.6569 17 16 15.6569 16 14C16 12.3431 14.6569 11 13 11C11.3431 11 10 12.3431 10 14C10 15.6569 11.3431 17 13 17Z"/>
                            <path  {...props}
                                   d="M21 6H17.83L16.59 4.65C16.4036 4.44539 16.1767 4.28191 15.9235 4.16999C15.6704 4.05807 15.3968 4.00017 15.12 4H8.72C8.89 4.3 9 4.63 9 5C9 6.1 8.1 7 7 7H6V8C6 9.1 5.1 10 4 10C3.63 10 3.3 9.89 3 9.72V20C3 21.1 3.9 22 5 22H21C22.1 22 23 21.1 23 20V8C23 6.9 22.1 6 21 6ZM13 19C10.24 19 8 16.76 8 14C8 11.24 10.24 9 13 9C15.76 9 18 11.24 18 14C18 16.76 15.76 19 13 19Z"/>
                      </g>
                  );
            case "drag":
                  return (
                      <path {...props} d="M0 0H10.9645V1.618H0V0ZM10.9645 4.85384H0V3.23584H10.9645V4.85384Z"/>
                  );
            case "warn":
                  return (
                      <path {...props}
                            d="M13.5652 0H2.43479C1.09216 0 0 1.09216 0 2.43479V10.087C0 11.4296 1.09216 12.5217 2.43479 12.5217H9.94297L13.3197 15.8984C13.3858 15.9645 13.4748 16 13.5652 16C13.6098 16 13.6557 15.9917 13.6981 15.9736C13.8282 15.92 13.9131 15.7927 13.9131 15.6522V12.4967C15.0915 12.3276 16 11.3113 16 10.0869V2.43479C16 1.09216 14.9078 0 13.5652 0ZM7.99998 10.4348C7.42467 10.4348 6.95651 9.96661 6.95651 9.3913C6.95651 8.81598 7.42467 8.34782 7.99998 8.34782C8.5753 8.34782 9.04346 8.81598 9.04346 9.3913C9.04346 9.96661 8.5753 10.4348 7.99998 10.4348ZM9.38853 3.20556L9.08869 6.64346C9.04904 7.20626 8.56972 7.65215 7.99998 7.65215C7.42398 7.65215 6.94539 7.20622 6.90573 6.6372L6.61216 3.20974C6.59129 2.91756 6.69146 2.63097 6.88764 2.42018C7.08521 2.208 7.36346 2.08696 7.65218 2.08696H8.34782C8.63651 2.08696 8.91479 2.208 9.11233 2.42018C9.30851 2.63165 9.4094 2.91756 9.38853 3.20556Z"/>
                  );
            case "archived":
                  return (
                      <path {...props}
                            d="M17.54 2.23L16.15 0.55C15.88 0.21 15.47 0 15 0H3C2.53 0 2.12 0.21 1.84 0.55L0.46 2.23C0.17 2.57 0 3.02 0 3.5V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V3.5C18 3.02 17.83 2.57 17.54 2.23ZM9 14.5L3.5 9H7V7H11V9H14.5L9 14.5ZM2.12 2L2.93 1H14.93L15.87 2H2.12Z"/>
                  );
            case "info":
                  return (
                      <g>
                            <path {...props}
                                  d="M7.99985 2.66666C7.2645 2.66666 6.6665 3.26463 6.6665 4C6.6665 4.73537 7.26447 5.33334 7.99985 5.33334C8.73522 5.33334 9.33319 4.73537 9.33319 4C9.33319 3.26463 8.73519 2.66666 7.99985 2.66666ZM7.99985 4.66666C7.63235 4.66666 7.33319 4.3675 7.33319 4C7.33319 3.6325 7.63235 3.33334 7.99985 3.33334C8.36735 3.33334 8.6665 3.6325 8.6665 4C8.6665 4.3675 8.36735 4.66666 7.99985 4.66666Z"
                                  fill="white"/>
                            <path {...props}
                                  d="M9 6H6.33334C6.14909 6 6 6.14909 6 6.33334V7.66669C6 7.85094 6.14909 8.00003 6.33334 8.00003H6.66669V13C6.66669 13.1843 6.81578 13.3334 7.00003 13.3334H9.00003C9.18428 13.3334 9.33337 13.1843 9.33337 13V6.33334C9.33334 6.14909 9.18425 6 9 6ZM8.66666 12.6667H7.33331V7.66666C7.33331 7.48241 7.18422 7.33331 6.99997 7.33331H6.66663V6.66666H8.66663V12.6667H8.66666Z"
                                  fill="white"/>
                            <path {...props}
                                  d="M8 0C3.58887 0 0 3.58887 0 8C0 12.4111 3.58887 16 8 16C12.4111 16 16 12.4111 16 8C16 3.58887 12.4111 0 8 0ZM8 15.3333C3.95638 15.3333 0.666656 12.0436 0.666656 8C0.666656 3.95638 3.95638 0.666656 8 0.666656C12.0436 0.666656 15.3333 3.95638 15.3333 8C15.3333 12.0436 12.0436 15.3333 8 15.3333Z"
                                  fill="white"/>
                      </g>
                  );
            default:
                  return <path/>;
      }
};

const SVGIcon = ({
                       name = "",
                       style = {},
                       fill,
                       viewBox = "",
                       width = "100%",
                       className = "",
                       height = "100%"
                 }) => (
    <svg
        width={width}
        style={style}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox || getViewBox(name)}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
          {getPath(name, {fill})}
    </svg>
);

SVGIcon.propTypes = {
      name: PropTypes.string,
      fill: PropTypes.string,
      width: PropTypes.string,
}
export default SVGIcon;
