import {
      COLLECTION_MODE,
      MUSIC_FILES,
      ACTIVE_COLLECTION_ID,
      ACTIVE_COLLECTION,
      TRACK_ARRAY,
      COLLECTION_TITLE
} from "../actions/types";

const defaultState = {
      mode: 'edit',
      files: [],
      activeCollectionId: 0,
      collection: {},
      trackArray: [], //tracks from active editing collection
      collectionTitle: '',
};

export default function collectionStates(state = defaultState, action) {
      switch (action.type) {
            case COLLECTION_MODE:
                  return {...state, mode: action.payload};
            case MUSIC_FILES:
                  return {...state, files: action.payload};
            case ACTIVE_COLLECTION_ID:
                  return {...state, activeCollectionId: action.payload};
            case ACTIVE_COLLECTION:
                  return {...state, collection: action.payload};
            case TRACK_ARRAY:
                  return {...state, trackArray: action.payload};
            case COLLECTION_TITLE:
                  return {...state, collectionTitle: action.payload};
            default:
                  return state
      }
}
