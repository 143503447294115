import React, {Component} from 'react';
import PageTitle from "../../components/pageTitle";
import InputSearch from "../../components/inputs/input-search";
import StudentsCollection from './components/collection';
import axios from 'axios';
import refreshToken from '../../api/refreshToken';
import LoaderComponent from "../musicManagement/components/loader";
import debounce from "lodash.debounce";
import SVGIcon from "../../components/svgIcon";
import {url} from '../../api/api';

class StudentsManagement extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  search: '',
                  loading: false,
                  formError: '',

                  semesters: [],
                  counter: 0,
            };

            this.onChangeDebounced = debounce(this.onChangeDebounced, 2000)
      }

      componentDidMount() {
            this.setState({
                  loading: true,
            });
            this.getData();
      }

      getData = () => {
            this.setState({
                  loading: true,
            });
            let token = localStorage.getItem('accessToken');
            let {search} = this.state;
            let path = `${url}/semesters`;

            if (search) {
                  path += '/search?term=' + search;
            } else {
                  path += '/all';
            }

            axios.get(path,
                {
                      headers: {
                            Authorization: 'Bearer ' + token,
                      },
                })
                .then(response => {
                      // console.log(response);
                      this.setState({
                            loading: false,
                            semesters: response.data.semesters,
                            formError: '',
                      });
                })
                .catch(error => {
                      // console.log(JSON.stringify(error));
                      this.setState({
                            loading: false,
                            formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                      });
                      if (error.response.status === 401) {
                            refreshToken(this.getData);
                      }
                });
      };

      search = (e) => {
            this.setState({
                  search: e.target.value,
                  counter: 0,
            });
            this.onChangeDebounced()
      };

      onChangeDebounced = () => {
            this.getData()
      };

      renderContent = () => {
            const {search, loading, semesters} = this.state;

            if (loading) {
                  return <LoaderComponent loading={loading}/>
            } else if (semesters.length === 0 && !loading && search) {
                  return <div className='empty-state'><p>There are no results for your search</p></div>
            } else if (semesters.length === 0 && !loading) {
                  return <div className='empty-state'><p>You don't have collections</p></div>
            } else if (semesters.length > 0 && !loading) {
                  return semesters.map((item, i) => {
                        return <StudentsCollection searchFunc={() => {
                              this.setState({
                                    loading: true
                              });
                        }} item={item} key={i} search={search}/>
                  })
            }
      };

      render() {
            const {search} = this.state;
            return (
                <div className='page-container'>
                      <PageTitle title='Students management'/>
                      <div className='top-content-block'>
                            <InputSearch placeholder='Search' value={search} onChange={(e) => this.search(e)}/>
                      </div>
                      <p className={this.state.formError !== '' ? 'form-error' : 'form-error hide'}>
                            <SVGIcon name="error" width='16' height='16'
                                     fill='#ED1566'/><span>{this.state.formError}</span></p>
                      {this.renderContent()}
                </div>
            );
      }
}

export default StudentsManagement;
