import React from 'react';
import SVGIcon from "../svgIcon";

export default function InputTextarea({
                                        label,
                                        value,
                                        placeholder,
                                        onChange,
                                        error
                                  }) {
  return <div className={!error ? 'input-block' : 'input-block error'}>
            <p className='input-label'>{label}</p>
            <textarea
                name='userEmail'
                placeholder={placeholder}
                value={value}
                onChange={onChange}
          className={!error ? 'textarea' : 'textarea error'}
            />
        <p className={!error ? 'input-error hide' : 'input-error'}><SVGIcon name="error" width='16' fill='#ED1566' /><span>{error}</span></p>
      </div>
}
