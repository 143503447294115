import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {collectionFiles, activeCollection, collectionTitle} from "../../actions";
import PageTitle from "../../components/pageTitle";
import StepsComponent from "./components/stepsComponent";
import InputText from "../../components/inputs/input-text";
import InputSelect from "../../components/inputs/input-select";
import UploadBigImage from "../../components/uploadBigImage";
import axios from "axios";
import refreshToken from "../../api/refreshToken";
import LoaderComponent from "./components/loader";
import ModalLeavePage from "./components/modalForLeave";
import ModalCropImage from "./components/modalForCrop";
import SVGIcon from "../../components/svgIcon";
import {url} from '../../api/api';

const steps = [
      {
            name: 'General ',
            active: true,
      },
      {
            name: 'Songs upload',
            active: false,
      },
      {
            name: 'Songs edit',
            active: false,
      }
];

class MusicManagementStep1 extends Component {
      constructor(props) {
            super(props);

            this.state = {
                  name: '',
                  nameError: '',
                  nameValid: true,

                  semesters: [],
                  selectedSemesters: [],
                  semestersValid: true,
                  semestersError: '',

                  image: null,

                  loading: true,
                  modalCrop: false,

                  file: {},
                  formError: '',
            }
      }

      onUnload = e => {
            const confirmationMessage = 'Some message';
            e.returnValue = confirmationMessage;
            return confirmationMessage;
      };

      UNSAFE_componentWillMount() {
            if (this.props.music.activeCollectionId === 0 && this.props.music.mode === 'edit') {
                  this.props.history.push('/music-management');
            }
      }

      componentDidMount() {
            if (Object.keys(this.props.music.collection).length) {
                  this.setState({
                        name: this.props.music.collection.title,
                        selectedSemesters: this.props.music.collection.semesters,
                        image: this.props.music.collection.image,
                  })
            }
            this.getSemestersList();
            window.addEventListener("beforeunload", this.onUnload);
      }

      componentWillUnmount() {
            window.removeEventListener("beforeunload", this.onUnload);
      };

      getSemestersList = () => {
            let token = localStorage.getItem('accessToken');
            axios.get(`${url}/semesters/all`,
                {
                      headers: {
                            Authorization: 'Bearer ' + token,
                      },
                })
                .then(response => {
                      // console.log(response.data);
                      this.setState({
                            semesters: response.data.semesters,
                            formError: '',
                            loading: false,
                      });
                })
                .catch(error => {
                      // console.log(JSON.stringify(error));
                      this.setState({
                            loading: false,
                            formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                      });
                      if (error.response.status === 401) {
                            refreshToken(this.getSemestersList);
                      }
                });
      };

      _handleUserInput = (name, event) => {
            let title = name;
            let value = event.target.value;
            if (event.target.value.charAt(0) === ' ') {
                  return;
            }
            if (value.length <= 255) {
                  this.setState({[title]: value, formError: '', edited: true},
                      () => {
                            this._validateField(title, value);
                      });
            }
      };

      _validateField(fieldName, value) {
            let nameError = this.state.nameError;
            let nameValid = this.state.nameValid;
            switch (fieldName) {
                  case 'text':
                        nameValid = value.length > 0;
                        nameError = nameValid ? '' : 'This field is required';
                        break;
                  default:
                        break;
            }

            this.setState({
                  nameError: nameError,
                  nameValid: nameValid,
            });
      };

      onClickNextButton = () => {
            const {activeCollectionId} = this.props.music;
            let token = localStorage.getItem('accessToken');
            let collectionObject = {};

            //Here we create new media element (image for collection)
            let imageBody = new FormData();
            if (this.state.image !== null && this.state.image !== undefined) {
                  if (!this.state.image.id) {
                        let file = this.state.image;
                        imageBody.append('file', file);
                        axios.post(`${url}/media`,
                            imageBody, {
                                  headers: {
                                        Authorization: 'Bearer ' + token,
                                  },
                            })
                            .then(response => {
                                  // console.log(response);
                                  collectionObject['imageId'] = response.data.media.id;
                                  collectionObject['image'] = this.state.image;
                                  collectionObject['title'] = this.state.name;
                                  collectionObject['semesters'] = this.state.selectedSemesters;
                                  this.setState({
                                        formError: '',
                                  });
                                  this.props.activeCollection(collectionObject);
                                  this.props.collectionTitle(this.state.name);
                                  this.props.music.mode === 'edit' ? this.props.history.push(`/music-management/${activeCollectionId}/add-files`) : this.props.history.push('/music-management/new-collection/add-files')
                            })
                            .catch(error => {
                                  // console.log(JSON.stringify(error.response));
                                  this.setState({
                                        loading: false,
                                        formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                                  });
                            });
                  } else {
                        collectionObject['title'] = this.state.name;
                        collectionObject['semesters'] = this.state.selectedSemesters;
                        if (this.state.image) {
                              collectionObject['image'] = this.state.image;
                        }

                        this.props.activeCollection(collectionObject);
                        this.props.collectionTitle(this.state.name);
                        this.props.music.mode === 'edit' ? this.props.history.push(`/music-management/${activeCollectionId}/add-files`) : this.props.history.push('/music-management/new-collection/add-files')
                  }
            } else {
                  collectionObject['title'] = this.state.name;
                  collectionObject['semesters'] = this.state.selectedSemesters;
                  if (this.state.image) {
                        collectionObject['image'] = this.state.image;
                  }

                  this.props.activeCollection(collectionObject);
                  this.props.collectionTitle(this.state.name);
                  this.props.music.mode === 'edit' ? this.props.history.push(`/music-management/${activeCollectionId}/add-files`) : this.props.history.push('/music-management/new-collection/add-files')

            }
      };

      deleteImage = () => {
            this.setState({
                  image: null,
            })
      };

      render() {
            const {semesters, name, selectedSemesters} = this.state;
            return (
                <div className='page-container'>
                      <PageTitle title={this.props.music.mode === 'edit' ? `Edit collection` : 'New collection'}
                                 backButton={true} backFunction={() => this.setState({modal: true, fromHeader: true,})}
                                 history={this.props.history} path='/music-management'/>

                      <div className='content-block import-page'>
                            <StepsComponent steps={steps}/>
                            <div className='content-wrapper music-collection-1'>
                                  <div className='collection-image-block'>
                                        <UploadBigImage value={this.state.image} onUpload={(e) => {
                                              this.setState({
                                                    modalCrop: true,
                                                    file: e.target.files[0],
                                              })
                                        }}/>
                                        <div className='fourth-button' onClick={this.deleteImage}>Delete image</div>
                                  </div>
                                  <div className='collection-data-block'>
                                        <InputText label='collection title *'
                                                   placeholder='Title'
                                                   value={this.state.name}
                                                   onChange={event => this._handleUserInput('name', event)}
                                                   defaultValue={this.state.name}
                                                   name='name'
                                                   error={this.state.nameValid}
                                                   errorText={this.state.nameError}/>
                                        {!this.state.loading ?
                                            <InputSelect data={semesters}
                                                         isMulti={true}
                                                         label='Collection/semester assigned'
                                                         error={false}
                                                         errorText={false}
                                                         value={this.state.selectedSemesters}
                                                         onChange={(array) => this.setState({selectedSemesters: array || []})}/> :
                                            <LoaderComponent loading={this.state.loading}/>}
                                  </div>
                            </div>
                            <p className={this.state.formError !== '' ? 'form-error' : 'form-error hide'}>
                                  <SVGIcon name="error" width='16' height='16'
                                           fill='#ED1566'/><span>{this.state.formError}</span></p>
                            <div className='content-footer two-buttons'>
                                  <button className='fourth-button'
                                          onClick={() => this.setState({
                                                modal: true,
                                          })}>Back
                                  </button>
                                  <button
                                      className={name.length > 0 ? 'first-button' : 'first-button disabled'}
                                      onClick={this.onClickNextButton}>Next
                                  </button>
                            </div>
                            {this.state.modalCrop && <ModalCropImage
                                file={this.state.file}
                                onPush={(croppedImage) => {
                                      this.setState({image: croppedImage, modalCrop: false})
                                }}
                                closeModal={() => this.setState({modalCrop: false})}
                            />}
                            {this.state.modal && <ModalLeavePage
                                leave={() => this.state.fromHeader ? this.props.history.push('/music-management') : this.props.history.goBack()}
                                no={() => {
                                      this.setState({modal: false, fromHeader: false})
                                }}/>}

                      </div>
                </div>
            );
      }
}

const mapStateToProps = state => {
      return {
            files: state.files,
            music: state.music,
      };
};

export default withRouter(connect(mapStateToProps, {
      collectionFiles,
      activeCollection,
      collectionTitle
})(MusicManagementStep1));

