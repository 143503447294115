import React from 'react';
import Select from 'react-select';
import SVGIcon from '../svgIcon';

export default function InputSelect({
                                          data,
                                          label,
                                          value,
                                          onChange,
                                          error,
                                          errorText,
                                          isMulti
                                    }) {
      return <div className={'input-block'}>
            <p className='input-label'>{label}</p>
            <Select isMulti={isMulti}
                    className='input-select'
                    value={value}
                    onChange={onChange}
                    options={data}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}/>
            <p className={error ? 'input-error' : 'input-error hide'}><SVGIcon name="error" width='16'
                                                                               fill='#ED1566'/><span>{errorText}</span>
            </p>
      </div>
}
