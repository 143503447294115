import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {activeNotification, notificationTitle} from "../../actions";
import axios from "axios";
import PageTitle from "../../components/pageTitle";
import StepsComponent from "../musicManagement/components/stepsComponent";
import UploadBigImage from "../../components/uploadBigImage";
import InputText from "../../components/inputs/input-text";
import InputTextarea from "../../components/inputs/input-textarea";
import ModalLeavePage from "../musicManagement/components/modalForLeave";
import ModalCropImage from "../musicManagement/components/modalForCrop";
import SVGIcon from "../../components/svgIcon";
import {url} from '../../api/api';

const steps = [
      {
            name: 'General ',
            active: true,
      },
      {
            name: 'Rules management',
            active: false,
      }
];

class NotificationsManagementStep1 extends Component {
      constructor(props) {
            super(props);

            this.state = {
                  title: '',
                  titleError: '',

                  text: '',

                  image: null,

                  loading: true,
                  formError: '',
                  modalCrop: false,

                  file: {},
            }
      }

      onUnload = e => {
            const confirmationMessage = 'Some message';
            e.returnValue = confirmationMessage;
            return confirmationMessage;
      };

      UNSAFE_componentWillMount() {
            if (this.props.notification.activeNotificationId === 0 && this.props.notification.mode === 'edit') {
                  this.props.history.push('/notifications-management');
            }
      }

      componentDidMount() {
            if (Object.keys(this.props.notification.notification).length) {
                  this.setState({
                        title: this.props.notification.notification.title,
                        image: this.props.notification.notification.image,
                        text: this.props.notification.notification.text,
                  })
            }
            window.addEventListener("beforeunload", this.onUnload);
      }

      componentWillUnmount() {
            window.removeEventListener("beforeunload", this.onUnload);
      };


      _handleUserInput = (name, event) => {
          let title = name;
          let value = event.target.value;
          this.setState({ [title]: value, formError: '' },
            () => {
                this._validateField(title, value);
            });
      };

      _validateField(fieldName, value) {
        let titleError = this.state.titleError;
        let textError = this.state.textError;

            switch (fieldName) {
                  case 'title':
                    if (value.length < 1) {
                      titleError = 'This field is required'
                    } else if (value.length > 255) {
                      titleError = 'The value must have at most 255 characters'
                    } else {
                      titleError = ''
                    }
                    break;
                  case 'text':
                if (value.length < 1) {
                  textError = 'This field is required'
                } else if (value.length > 2000) {
                  textError = 'The value must have at most 2000 characters'
                } else {
                  textError = ''
                }
                break;
                  default:
                        break;
            }

            this.setState({
              titleError,
                  textError,
            });
      };

      onClickNextButton = () => {
            const {activeNotificationId} = this.props.notification;
            const {semesters, sendAt, clause} = this.props.notification.notification;
            let token = localStorage.getItem('accessToken');
            let notificationObject = {};

            //Here we create new media element (image for notification)
            let imageBody = new FormData();
            if (this.state.image !== null && this.state.image !== undefined) {
                  if (!this.state.image.id) {
                        let file = this.state.image;
                        imageBody.append('file', file);
                        axios.post(`${url}/media`,
                            imageBody, {
                                  headers: {
                                        Authorization: 'Bearer ' + token,
                                  },
                            })
                            .then(response => {
                                  // console.log(response);
                                  notificationObject['imageId'] = response.data.media.id;
                                  notificationObject['image'] = this.state.image;
                                  notificationObject['title'] = this.state.title;
                                  notificationObject['text'] = this.state.text;
                                  notificationObject['semesters'] = semesters;
                                  notificationObject['sendAt'] = sendAt;
                                  notificationObject['clause'] = clause;
                                  this.setState({
                                        formError: '',
                                  });
                                  this.props.activeNotification(notificationObject);
                                  this.props.notificationTitle(this.state.title);
                                  this.props.notification.mode === 'edit' ? this.props.history.push(`/notifications-management/${activeNotificationId}/rules`) : this.props.history.push('/notifications-management/new-notification/rules')
                            })
                            .catch(error => {
                                  // console.log(JSON.stringify(error.response));
                                  this.setState({
                                        loading: false,
                                        formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                                  });
                            });
                  } else {
                        notificationObject['title'] = this.state.title;
                        notificationObject['text'] = this.state.text;
                        if (this.state.image) {
                              notificationObject['image'] = this.state.image;
                        }
                        notificationObject['semesters'] = semesters;
                        notificationObject['sendAt'] = sendAt;
                        notificationObject['clause'] = clause;

                        this.props.activeNotification(notificationObject);
                        this.props.notificationTitle(this.state.title);
                        this.props.notification.mode === 'edit' ? this.props.history.push(`/notifications-management/${activeNotificationId}/rules`) : this.props.history.push('/notifications-management/new-notification/rules')

                  }
            } else {
                  notificationObject['title'] = this.state.title;
                  notificationObject['text'] = this.state.text;
                  if (this.state.image) {
                        notificationObject['image'] = this.state.image;
                  }
                  notificationObject['semesters'] = semesters;
                  notificationObject['sendAt'] = sendAt;
                  notificationObject['clause'] = clause;

                  this.props.activeNotification(notificationObject);
                  this.props.notificationTitle(this.state.title);
                  this.props.notification.mode === 'edit' ? this.props.history.push(`/notifications-management/${activeNotificationId}/rules`) : this.props.history.push('/notifications-management/new-notification/rules')
            }
      };

      deleteImage = () => {
            this.setState({
                  image: null,
            })
      };

      render() {
            const {title, text} = this.state;
            return (
                <div className='page-container'>
                      <PageTitle
                          title={this.props.notification.mode === 'edit' ? `Edit notification` : 'New notification'}
                          backButton={true} backFunction={() => this.setState({modal: true, fromHeader: true,})}
                          history={this.props.history} path='/notifications-management'/>

                      <div className='content-block notifications-block'>
                            <StepsComponent steps={steps} two={true}/>
                            <div className='content-wrapper music-collection-1'>
                                  <div className='collection-image-block'>
                                        <UploadBigImage
                                            value={this.state.image}
                                            onUpload={(e) => {
                                                  this.setState({
                                                        modalCrop: true,
                                                        file: e.target.files[0],
                                                  })
                                            }}/>
                                        <div className='fourth-button' onClick={this.deleteImage}>Delete image</div>
                                  </div>
                                  <div className='collection-data-block'>
                                        <InputText label='notification title *'
                                                   placeholder='Title'
                                                   value={this.state.title}
                                                   onChange={event => this._handleUserInput('title', event)}
                                                   defaultValue={this.state.title}
                                                   name='name'
                                                  error={!this.state.titleError}
                                                   errorText={this.state.titleError}
                                                   />
                                        <InputTextarea label='Text *' placeholder='Text' error={true}
                                                       value={this.state.text}
                        error={this.state.textError}
                                                       onChange={event => this._handleUserInput('text', event)}/>

                                  </div>
                            </div>
                            <p className={this.state.formError !== '' ? 'form-error' : 'form-error hide'}>
                                  <SVGIcon name="error" width='16' height='16'
                                           fill='#ED1566'/><span>{this.state.formError}</span></p>
                            <div className='content-footer two-buttons'>
                                  <button className='fourth-button'
                                          onClick={() => this.setState({
                                                modal: true,
                                          })}>Back
                                  </button>
                                  <button
                      className={title.length < 1 || text.length < 1 || this.state.titleError || this.state.textError ? 'first-button disabled' : 'first-button'}
                                      onClick={this.onClickNextButton}>Next
                                  </button>
                            </div>
                            {this.state.modalCrop && <ModalCropImage
                                file={this.state.file}
                                onPush={(croppedImage) => {
                                      this.setState({image: croppedImage, modalCrop: false})
                                }}
                                closeModal={() => this.setState({modalCrop: false})}
                            />}
                            {this.state.modal && <ModalLeavePage
                                leave={() => this.state.fromHeader ? this.props.history.push('/notifications-management') : this.props.history.goBack()}
                                no={() => {
                                      this.setState({modal: false, fromHeader: false})
                                }}/>}

                      </div>
                </div>
            );
      }
}

const mapStateToProps = state => {
      return {
            notification: state.notification
      };
};

export default withRouter(connect(mapStateToProps, {
      activeNotification,
      notificationTitle
})(NotificationsManagementStep1));

