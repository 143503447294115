import {IMPORT_FILES} from "../actions/types";

const defaultState = {
      files: [],
      uuid: null
};

export default function importDataFiles (state = defaultState, action) {
      switch (action.type) {
            case IMPORT_FILES:
                  return {...state, files: action.payload.files, uuid: action.payload.uuid };
            default:
                  return state
      }
}
