export const AUTHORIZE = 'authorize';
export const IMPORT_FILES = 'importFiles';
export const COLLECTION_MODE = 'collectionMode';
export const MUSIC_FILES = 'collectionFiles';
export const ACTIVE_COLLECTION_ID = 'activeCollectionID';
export const ACTIVE_COLLECTION = 'activeCollection';
export const TRACK_ARRAY = 'trackArray';
export const COLLECTION_TITLE = 'collectionTitle';
export const NOTIFICATION_MODE = 'notificationMode';
export const ACTIVE_NOTIFICATION_ID = 'activeNotificationID';
export const ACTIVE_NOTIFICATION = 'activeNotification';
export const NOTIFICATION_TITLE = 'notificationTitle';
export const NOTIFICATION_TYPE = 'notificationType';
export const NOTIFICATION_ARCHIVED = 'notificationArchived';
export const NOTIFICATION_FOR_COMPARE = 'notificationForCompare';
