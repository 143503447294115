import React from 'react';
import SVGIcon from "../svgIcon";


export default function InputEmail({
                                         label,
                                         value,
                                         placeholder,
                                         onChange,
    error,
    errorText
                                   }) {
      return <div className={error ? 'input-block' : 'input-block error'}>
            <p className='input-label'>{label}</p>
            <input
                name='userEmail'
                type='email'
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                className={error ? 'input' : 'input error'}
            />
            <p className='input-error'><SVGIcon name="error" width='16' fill='#ED1566' /><span>{errorText}</span></p>
      </div>
}
