import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from "react-redux";
import {collectionFiles, collectionMode, activeNotification} from "../../actions";
import PageTitle from "../../components/pageTitle";
import StepsComponent from "../musicManagement/components/stepsComponent";
import InputSelect from "../../components/inputs/input-select";
import axios from "axios/index";
import refreshToken from "../../api/refreshToken";
import ModalLeavePage from "../musicManagement/components/modalForLeave";
import moment from 'moment';
import SVGIcon from '../../components/svgIcon';
import {url} from "../../api/api";

const steps = [
      {
            name: 'General ',
            active: false,
      },
      {
            name: 'Rules management',
            active: true,
      }
];

class NotificationsManagementStep2 extends Component {
      constructor(props) {
            super(props);

            this.state = {
                  title: '',
                  titleError: '',
                  titleValid: true,

                  text: '',

                  image: null,

                  loading: true,

                  days: [],
                  selectedDay: '1',
                  months: [],
                  selectedMonth: {
                        name: 'January',
                  },
                  years: [],
                  selectedYear: '',
                  times: [
                        {
                              name: '12:00 AM'
                        },
                        {
                              name: '12:15 AM'
                        },
                        {
                              name: '12:30 AM'
                        },
                        {
                              name: '12:45 AM'
                        },
                        {
                              name: '01:00 AM'
                        },
                        {
                              name: '01:15 AM'
                        },
                        {
                              name: '01:30 AM'
                        },
                        {
                              name: '01:45 AM'
                        },
                        {
                              name: '02:00 AM'
                        },
                        {
                              name: '02:15 AM'
                        },
                        {
                              name: '02:30 AM'
                        },
                        {
                              name: '02:45 AM'
                        },
                        {
                              name: '03:00 AM'
                        },
                        {
                              name: '03:15 AM'
                        },
                        {
                              name: '03:30 AM'
                        },
                        {
                              name: '03:45 AM'
                        },
                        {
                              name: '04:00 AM'
                        },
                        {
                              name: '04:15 AM'
                        },
                        {
                              name: '04:30 AM'
                        },
                        {
                              name: '04:45 AM'
                        },
                        {
                              name: '05:00 AM'
                        },
                        {
                              name: '05:15 AM'
                        },
                        {
                              name: '05:30 AM'
                        },
                        {
                              name: '05:45 AM'
                        },
                        {
                              name: '06:00 AM'
                        },
                        {
                              name: '06:15 AM'
                        },
                        {
                              name: '06:30 AM'
                        },
                        {
                              name: '06:45 AM'
                        },
                        {
                              name: '07:00 AM'
                        },
                        {
                              name: '07:15 AM'
                        },
                        {
                              name: '07:30 AM'
                        },
                        {
                              name: '07:45 AM'
                        },
                        {
                              name: '08:00 AM'
                        },
                        {
                              name: '08:15 AM'
                        },
                        {
                              name: '08:30 AM'
                        },
                        {
                              name: '08:45 AM'
                        },
                        {
                              name: '09:00 AM'
                        },
                        {
                              name: '09:15 AM'
                        },
                        {
                              name: '09:30 AM'
                        },
                        {
                              name: '09:45 AM'
                        },
                        {
                              name: '10:00 AM'
                        },
                        {
                              name: '10:15 AM'
                        },
                        {
                              name: '10:30 AM'
                        },
                        {
                              name: '10:45 AM'
                        },
                        {
                              name: '11:00 AM'
                        },
                        {
                              name: '11:15 AM'
                        },
                        {
                              name: '11:30 AM'
                        },
                        {
                              name: '11:45 AM'
                        },
                        {
                              name: '12:00 PM'
                        },
                        {
                              name: '12:15 PM'
                        },
                        {
                              name: '12:30 PM'
                        },
                        {
                              name: '12:45 PM'
                        },
                        {
                              name: '01:00 PM'
                        },
                        {
                              name: '01:15 PM'
                        },
                        {
                              name: '01:30 PM'
                        },
                        {
                              name: '01:45 PM'
                        },
                        {
                              name: '02:00 PM'
                        },
                        {
                              name: '02:15 PM'
                        },
                        {
                              name: '02:30 PM'
                        },
                        {
                              name: '02:45 PM'
                        },
                        {
                              name: '03:00 PM'
                        },
                        {
                              name: '03:15 PM'
                        },
                        {
                              name: '03:30 PM'
                        },
                        {
                              name: '03:45 PM'
                        },
                        {
                              name: '04:00 PM'
                        },
                        {
                              name: '04:15 PM'
                        },
                        {
                              name: '04:30 PM'
                        },
                        {
                              name: '04:45 PM'
                        },
                        {
                              name: '05:00 PM'
                        },
                        {
                              name: '05:15 PM'
                        },
                        {
                              name: '05:30 PM'
                        },
                        {
                              name: '05:45 PM'
                        },
                        {
                              name: '06:00 PM'
                        },
                        {
                              name: '06:15 PM'
                        },
                        {
                              name: '06:30 PM'
                        },
                        {
                              name: '06:45 PM'
                        },
                        {
                              name: '07:00 PM'
                        },
                        {
                              name: '07:15 PM'
                        },
                        {
                              name: '07:30 PM'
                        },
                        {
                              name: '07:45 PM'
                        },
                        {
                              name: '08:00 PM'
                        },
                        {
                              name: '08:15 PM'
                        },
                        {
                              name: '08:30 PM'
                        },
                        {
                              name: '08:45 PM'
                        },
                        {
                              name: '09:00 PM'
                        },
                        {
                              name: '09:15 PM'
                        },
                        {
                              name: '09:30 PM'
                        },
                        {
                              name: '09:45 PM'
                        },
                        {
                              name: '10:00 PM'
                        },
                        {
                              name: '10:15 PM'
                        },
                        {
                              name: '10:30 PM'
                        },
                        {
                              name: '10:45 PM'
                        },
                        {
                              name: '11:00 PM'
                        },
                        {
                              name: '11:15 PM'
                        },
                        {
                              name: '11:30 PM'
                        },
                        {
                              name: '11:45 PM'
                        },
                  ],
                  selectedTime: {
                        name: '02:00 PM'
                  },

                  selectedSemesters: [],
                  dateError: '',
                  yearsAge: [
                        {
                              name: '0'
                        },
                        {
                              name: '1'
                        },
                        {
                              name: '2'
                        },
                        {
                              name: '3'
                        },
                        {
                              name: '4'
                        },
                        {
                              name: '5'
                        },
                        {
                              name: '6'
                        },
                        {
                              name: '7'
                        },
                        {
                              name: '8'
                        },
                        {
                              name: '9'
                        },
                        {
                              name: '10'
                        },
                        {
                              name: '11'
                        },
                        {
                              name: '12'
                        },
                        {
                              name: '13'
                        },
                        {
                              name: '14'
                        },
                        {
                              name: '15'
                        },
                        {
                              name: '16'
                        },
                        {
                              name: '17'
                        },
                        {
                              name: '18'
                        },
                        {
                              name: '19'
                        },
                        {
                              name: '20'
                        },
                        {
                              name: '21'
                        },
                  ],
                  monthsAge: [
                        {
                              name: '0'
                        },
                        {
                              name: '1'
                        },
                        {
                              name: '2'
                        },
                        {
                              name: '3'
                        },
                        {
                              name: '4'
                        },
                        {
                              name: '5'
                        },
                        {
                              name: '6'
                        },
                        {
                              name: '7'
                        },
                        {
                              name: '8'
                        },
                        {
                              name: '9'
                        },
                        {
                              name: '10'
                        },
                        {
                              name: '11'
                        },
                  ],
                  selectedYearFrom: {
                        name: '12'
                  },
                  selectedMonthFrom: {
                        name: '3'
                  },
                  selectedYearTo: {
                        name: '14'
                  },
                  selectedMonthTo: {
                        name: '11'
                  }
            }
      }

      onUnload = e => { // the method that will be used for both add and remove event
            const confirmationMessage = 'Some message';
            e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
            return confirmationMessage;
      };

      UNSAFE_componentWillMount() {
            if (this.props.notification.activeNotificationId === 0 && this.props.notification.mode === 'edit') {
                  this.props.history.push('/notifications-management');
            }
      }

      componentDidMount() {
            this.getSemestersList();
            window.addEventListener("beforeunload", this.onUnload);

            if (this.props.notification.mode === 'edit') {
                  if (this.props.notification.notificationType === 1) {
                        this.editCommon();
                  } else {
                        this.editChildhood();
                  }
            } else {
                  if (this.props.notification.notificationType === 1) {
                        this.createNewCommon();
                  } else {
                        this.createNewChildhood();
                  }
            }
      }

      createNewCommon = () => {
            let year = moment().format('YYYY');
            let month = moment().format('MMMM');
            let monthNumber = moment().format('MM');
            let day = moment().format('DD');
            this.setState({
                  selectedYear: {
                        name: year,
                  },
                  selectedMonth: {
                        name: month,
                        number: monthNumber
                  },
                  selectedDay: {
                        name: day,
                  },
                  selectedSemesters: this.props.notification.notification.semesters,
            });
            this.yearsCounter();
            this.monthCounter();
            this.daysCounter(monthNumber);
      };

      editCommon = () => {
            let timeString = this.props.notification.notification.clause.sendAt;
            let year = moment(timeString).format('YYYY');
            let month = moment(timeString).format('MMMM');
            let monthNumber = moment(timeString).format('MM');
            let day = moment(timeString).format('DD');
            let time = moment(timeString).format('hh:mm A');
            this.setState({
                  selectedYear: {
                        name: year,
                  },
                  selectedMonth: {
                        name: month,
                        number: monthNumber
                  },
                  selectedDay: {
                        name: day,
                  },
                  selectedTime: {
                        name: time,
                  },
                  selectedSemesters: this.props.notification.notification.semesters,
            });
            this.yearsCounter();
            this.monthCounter();
            this.daysCounter(monthNumber);
      };

      createNewChildhood = () => {
            this.setState({
                  selectedYearFrom: {
                        name: '0'
                  },
                  selectedMonthFrom: {
                        name: '1'
                  },
                  selectedYearTo: {
                        name: '21'
                  },
                  selectedMonthTo: {
                        name: '11'
                  }
            });
      };

      editChildhood = () => {
            const {notification} = this.props.notification;
            let timeString = this.props.notification.notification.clause.sendAtTime;
            let hours = timeString.substring(0, 2);
            let minutes = timeString.substring(3, 5);
            console.log(hours + minutes);
            let hoursTime;
            let pref;
            if (hours > '12') {
                  hoursTime = hours - 12;
                  pref = "PM"
            } else if (hours === '00') {
                  hoursTime = 12;
                  pref = "AM"
            } else {
                  hoursTime = hours;
                  pref = "AM"
            }

            let time = `${hoursTime}:${minutes} ${pref}`;

            let yearsFrom = Math.floor(notification.clause.ageFrom / 12);
            let mothsFrom = notification.clause.ageFrom % 12;
            let yearsTo = Math.floor(notification.clause.ageTo / 12);
            let mothsTo = notification.clause.ageTo % 12;

            this.setState({
                  selectedYearFrom: {
                        name: yearsFrom
                  },
                  selectedMonthFrom: {
                        name: mothsFrom
                  },
                  selectedYearTo: {
                        name: yearsTo
                  },
                  selectedMonthTo: {
                        name: mothsTo
                  },
                  selectedSemesters: notification.semesters,
                  selectedTime: {
                        name: time,
                  },
            })
      };

      daysCounter = (year, month) => {
            let dateString = year + '-' + month;
            const days = [];
            let dateStart3 = 1;
            const dateEnd3 = moment(dateString, "YYYY-MM").daysInMonth();
            while (dateStart3 <= dateEnd3) {
                  let dayObject = {};
                  if (dateStart3 < 10) {
                        dayObject.name = '0' + dateStart3;
                  } else {
                        dayObject.name = dateStart3;
                  }
                  days.push(dayObject);
                  dateStart3++
            }
            this.setState({
                  days: days
            });
      };

      monthCounter = () => {
            const months = [];
            const dateStart2 = moment();
            const dateEnd2 = moment().add(11, 'month');
            while (dateEnd2.diff(dateStart2, 'months') >= 0) {
                  let monthObject = {};
                  monthObject.name = dateStart2.format('MMMM');
                  monthObject.number = dateStart2.format('MM');
                  months.push(monthObject);
                  dateStart2.add(1, 'month')
            }
            this.setState({
                  months: months
            });
      };

      yearsCounter = () => {
            const years = [];
            const dateStart = moment();
            const dateEnd = moment().add(10, 'y');
            while (dateEnd.diff(dateStart, 'years') >= 0) {
                  let yearObject = {};
                  yearObject.name = dateStart.format('YYYY');
                  years.push(yearObject);
                  dateStart.add(1, 'year')
            }
            this.setState({
                  years: years
            });
      };

      componentWillUnmount() {
            window.removeEventListener("beforeunload", this.onUnload);
      };

      getSemestersList = () => {
            let token = localStorage.getItem('accessToken');
            axios.get(`${url}/semesters/all`,
                {
                      headers: {
                            Authorization: 'Bearer ' + token,
                      },
                })
                .then(response => {
                      // console.log(response.data);
                      this.setState({
                            semesters: response.data.semesters,
                            loading: false,
                            formError: '',
                      });
                })
                .catch(error => {
                      // console.log(JSON.stringify(error));
                      this.setState({
                            loading: false,
                            formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                      });
                      if (error.response.status === 401) {
                            refreshToken(this.getSemestersList);
                      }
                });
      };

      saveNotification = () => {
            this.setState({
                  loading: true,
            });

            let token = localStorage.getItem('accessToken');
            if (this.props.notification.notificationType === 1) {
                  let notificationObject = {};
                  let semestersArray = [];
                  this.state.selectedSemesters.forEach((item) => {
                        semestersArray.push(item.id);
                  });
                  let hours = this.state.selectedTime.name.substring(0, 2);
                  let minutes = this.state.selectedTime.name.substring(3, 5);
                  let timeZone = this.state.selectedTime.name.substring(6, 8);
                  let timeString = '';

                  if (timeZone === 'AM' && hours === '12') {
                        timeString = '00'
                  } else if (timeZone === 'AM') {
                        timeString = hours
                  } else if (timeZone === 'PM' && hours === '12') {
                        timeString = parseInt(hours)
                  } else {
                        timeString = 12 + parseInt(hours)
                  }

                  let today = moment();
                  let string = `${this.state.selectedYear.name}-${this.state.selectedMonth.number}-${this.state.selectedDay.name} ${timeString}:${minutes}:00`;

                  let timeForChecking = moment(string);
                  if (timeForChecking.diff(today) < 0) {
                        this.setState({
                              dateError: 'You have selected the past date. Please change your selection.',
                        });
                        return;
                  }

                  notificationObject.title = this.props.notification.notificationTitle;
                  notificationObject.text = this.props.notification.notification.text;
                  notificationObject.imageId = this.props.notification.notification.imageId;
                  notificationObject.semesters = semestersArray;
                  notificationObject.sendAt = string;
                  if (this.props.notification.mode === 'edit') {
                        axios.put(`${url}/notifications/oneTime/${this.props.notification.activeNotificationId}`, notificationObject,
                            {
                                  headers: {
                                        Authorization: 'Bearer ' + token,
                                  },
                            })
                            .then(response => {
                                  // console.log(response.data);
                                  this.setState({
                                        semesters: response.data.semesters,
                                        loading: false,
                                        dateError: '',
                                  });
                                  this.props.activeNotification({});
                                  this.props.history.push(`/notifications-management/${this.props.notification.activeNotificationId}/success`)
                            })
                            .catch(error => {
                                  // console.log(JSON.stringify(error));
                                  if (error.response.status === 401) {
                                        refreshToken(this.saveNotification);
                                  } else {
                                        this.setState({
                                              loading: false,
                                              dateError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                                        });
                                  }
                            });
                  } else {
                        axios.post(`${url}/notifications/oneTime`, notificationObject,
                            {
                                  headers: {
                                        Authorization: 'Bearer ' + token,
                                  },
                            })
                            .then(response => {
                                  // console.log(response.data);
                                  this.setState({
                                        loading: false,
                                        dateError: '',
                                  });
                                  this.props.activeNotification({});
                                  this.props.history.push(`/notifications-management/new-notification/success`)
                            })
                            .catch(error => {
                                  // console.log(JSON.stringify(error));
                                  if (error.response.status === 401) {
                                        refreshToken(this.saveNotification);
                                  } else {
                                        this.setState({
                                              loading: false,
                                              dateError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                                        });
                                  }
                            });
                  }
            } else {
                  let notificationObject = {};
                  let semestersArray = [];
                  this.state.selectedSemesters.forEach((item) => {
                        semestersArray.push(item.id);
                  });
                  let hours = this.state.selectedTime.name.substring(0, 2);
                  let minutes = this.state.selectedTime.name.substring(3, 5);
                  let timeZone = this.state.selectedTime.name.substring(6, 8);
                  let timeString = '';

                  if (timeZone === 'AM' && hours === '12') {
                        timeString = '00'
                  } else if (timeZone === 'AM') {
                        timeString = hours
                  } else if (timeZone === 'PM' && hours === '12') {
                        timeString = parseInt(hours)
                  } else {
                        timeString = 12 + parseInt(hours)
                  }

                  let string = `${timeString}:${minutes}`;

                  let ageFrom = this.state.selectedYearFrom.name * 12 + parseInt(this.state.selectedMonthFrom.name);
                  let ageTo = this.state.selectedYearTo.name * 12 + parseInt(this.state.selectedMonthTo.name);

                  if (ageFrom > ageTo) {
                        this.setState({
                              dateError: 'Your age "From" is later than the age "To". Please change your selection.'
                        });
                        return;
                  }
                  notificationObject.title = this.props.notification.notificationTitle;
                  notificationObject.text = this.props.notification.notification.text;
                  notificationObject.imageId = this.props.notification.notification.imageId;
                  notificationObject.semesters = semestersArray;
                  notificationObject.sendAtTime = string;
                  notificationObject.ageFrom = ageFrom;
                  notificationObject.ageTo = ageTo;
                  if (this.props.notification.mode === 'edit') {
                        axios.put(`${url}/notifications/age/${this.props.notification.activeNotificationId}`, notificationObject,
                            {
                                  headers: {
                                        Authorization: 'Bearer ' + token,
                                  },
                            })
                            .then(response => {
                                  // console.log(response.data);
                                  this.setState({
                                        loading: false,
                                        dateError: '',
                                  });
                                  this.props.history.push(`/notifications-management/${this.props.notification.activeNotificationId}/success`)
                            })
                            .catch(error => {
                                  // console.log(JSON.stringify(error));
                                  if (error.response.status === 401) {
                                        refreshToken(this.saveNotification);
                                  } else {
                                        this.setState({
                                              loading: false,
                                              dateError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                                        });
                                  }
                            });
                  } else {
                        axios.post(`${url}/notifications/age`, notificationObject,
                            {
                                  headers: {
                                        Authorization: 'Bearer ' + token,
                                  },
                            })
                            .then(response => {
                                  // console.log(response.data);
                                  this.setState({
                                        loading: false,
                                        dateError: '',
                                  });
                                  this.props.history.push(`/notifications-management/new-notification/success`)
                            })
                            .catch(error => {
                                  // console.log(JSON.stringify(error));
                                  if (error.response.status === 401) {
                                        refreshToken(this.saveNotification);
                                  } else {
                                        this.setState({
                                              loading: false,
                                              dateError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                                        });
                                  }
                            });
                  }
            }

      };

      renderFields = () => {
            if (this.props.notification.notificationType === 1) {
                  return <div className='input-block'>
                        <p className='input-label'>date*</p>
                        <div className='dates-block'>
                              <InputSelect data={this.state.days}
                                           value={this.state.selectedDay}
                                           error={false}
                                           errorText={false}
                                           onChange={(array) => this.setState({selectedDay: array, dateError: ''})}/>
                              <InputSelect data={this.state.months}
                                           value={this.state.selectedMonth}
                                           error={false}
                                           errorText={false}
                                           onChange={(array) => {
                                                 this.setState({selectedMonth: array, dateError: ''});
                                                 this.daysCounter(this.state.selectedYear.name, array.number)
                                           }}/>
                              <InputSelect data={this.state.years}
                                           value={this.state.selectedYear}
                                           error={false}
                                           errorText={false}
                                           onChange={(array) => {
                                                 this.setState({selectedYear: array, dateError: ''});
                                                 this.daysCounter(array.name, this.state.selectedMonth.number)
                                           }}/>
                        </div>
                  </div>
            } else {
                  return <div className='input-block'>
                        <p className='input-label'>student age*</p>
                        <div className='ages-block'>
                              <p>From</p>
                              <InputSelect data={this.state.yearsAge}
                                           value={this.state.selectedYearFrom}
                                           error={false}
                                           errorText={false}
                                           onChange={(array) => {
                                                 this.setState({selectedYearFrom: array, dateError: ''})
                                           }}/>
                              <InputSelect data={this.state.monthsAge}
                                           value={this.state.selectedMonthFrom}
                                           error={false}
                                           errorText={false}
                                           onChange={(array) => {
                                                 this.setState({selectedMonthFrom: array, dateError: ''});
                                           }}/>
                              <p>To</p>
                              <InputSelect data={this.state.yearsAge}
                                           value={this.state.selectedYearTo}
                                           error={false}
                                           errorText={false}
                                           onChange={(array) => {
                                                 this.setState({selectedYearTo: array, dateError: ''});
                                           }}/>
                              <InputSelect data={this.state.monthsAge}
                                           value={this.state.selectedMonthTo}
                                           error={false}
                                           errorText={false}
                                           onChange={(array) => {
                                                 this.setState({selectedMonthTo: array, dateError: ''});
                                           }}/>
                        </div>
                  </div>
            }
      };


      render() {
            const {semesters, selectedSemesters, times, selectedTime} = this.state;
            return (
                <div className='page-container'>
                      <PageTitle
                          title={this.props.notification.mode === 'edit' ? `Edit notification` : 'New notification'}
                          backButton={true} backFunction={() => this.setState({modal: true, fromHeader: true,})}
                          history={this.props.history} path='/notifications-management'/>

                      <div className='content-block notifications-block'>
                            <StepsComponent steps={steps} two={true}/>
                            <div className='content-wrapper notification-selects'>
                                  <div className='select-block'>
                                        <InputSelect data={semesters}
                                                     isMulti={true}
                                                     label='Collection/semester assigned*'
                                                     error={this.state.semestersValid}
                                                     errorText={this.state.semestersError}
                                                     value={selectedSemesters}
                                                     onChange={(array) => this.setState({
                                                           selectedSemesters: array,
                                                           dateError: ''
                                                     })}/>
                                        <div className='first-button'
                                             onClick={() => this.setState({selectedSemesters: semesters})}>Select all
                                        </div>
                                  </div>
                                  {this.renderFields()}
                                  <InputSelect data={times}
                                               label='time*'
                                               error={false}
                                               errorText={false}
                                               value={selectedTime}
                                               onChange={(array) => this.setState({
                                                     selectedTime: array,
                                                     dateError: ''
                                               })}/>
                            </div>
                            <p className={this.state.dateError !== '' ? 'form-error' : 'form-error hide'}>
                                  <SVGIcon name="error" width='16' height='16'
                                           fill='#ED1566'/><span>{this.state.dateError}</span></p>
                            <div className='content-footer two-buttons'>
                                  <button className='fourth-button'
                                          onClick={() => this.setState({
                                                modal: true,
                                          })}>Back
                                  </button>
                                  <button
                                      className={selectedSemesters ? 'first-button' : 'first-button disabled'}
                                      onClick={this.saveNotification}>Save notification
                                  </button>
                            </div>
                            {this.state.modal && <ModalLeavePage
                                leave={() => this.state.fromHeader ? this.props.history.push('/notifications-management') : this.props.history.goBack()}
                                no={() => {
                                      this.setState({modal: false, fromHeader: false})
                                }}/>}
                      </div>
                </div>
            );
      }
}

const mapStateToProps = state => {
      return {
            notification: state.notification
      };
};

export default withRouter(connect(mapStateToProps, {
      collectionMode,
      collectionFiles,
      activeNotification
})(NotificationsManagementStep2));

