import React, {Component} from 'react';
import {connect} from "react-redux";
import {collectionFiles, activeCollection, activeCollectionID} from "../../actions";
import {withRouter} from 'react-router-dom';
import PageTitle from "../../components/pageTitle";
import StepsComponent from "./components/stepsComponent";
import TrackComponent from "./components/track";
import axios from "axios";
import LoaderComponent from "./components/loader";
import refreshToken from "../../api/refreshToken";
import ModalLeavePage from "./components/modalForLeave";
import SVGIcon from "../../components/svgIcon";
import ModalDeleteCollectionTrack from "./components/modalForDeleteTrack";
import {url} from "../../api/api";

const steps = [
      {
            name: 'General ',
            active: false,
      },
      {
            name: 'Songs upload',
            active: true,
      },
      {
            name: 'Songs edit',
            active: false,
      }
];

class MusicManagementStep2_2 extends Component {
      constructor(props) {
            super(props);

            this.state = {
                  files: [],
                  tracksArray: [],

                  filesLoaded: false,
                  collectionImageId: 0,

                  loadingTracks: false, //tracks loader
                  loadingCollection: false, //collections loader
                  tracksDownloaded: false,
                  mp3blocker: false, //check if every track have mp3 file

                  changes: true, //check changes after first tracks download
                  existingTrack: [],

                  formError: '',
                  tracksError: '',
                  modalForDelete: false,
            };

            this.track = [];
      }

      onUnload = e => { // the method that will be used for both add and remove event
            const confirmationMessage = 'Some message';
            e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
            return confirmationMessage;
      };

      componentDidMount() {
            window.addEventListener("beforeunload", this.onUnload);
      };

      componentWillUnmount() {
            window.removeEventListener("beforeunload", this.onUnload);
      };

      UNSAFE_componentWillMount() {
            if (this.props.music.activeCollectionId === 0 && this.props.music.collection.title === undefined) {
                  this.props.history.push('/music-management');
            }

            let filesArray = this.props.music.files;
            let cleanedFilesArray = filesArray.slice();
            let token = localStorage.getItem('accessToken');

            //here we are checking - is there existing track with this name
            if (this.props.music.mode === 'edit') {
                  let initialArray = this.props.music.trackArray;
                  initialArray.forEach((item, i) => {
                        item['songId'] = item.id;
                        item['name'] = item.title;
                        item['mp3'] = true;
                        if (item.image !== null) {
                              item['imageId'] = item.image.id;
                        }
                  });

                  if (filesArray.length > 0) {
                        filesArray.forEach((item, i) => {
                              initialArray.forEach((track, j) => {
                                    if (item.name.substring(0, item.name.indexOf('.')) === track.name) {
                                          if (['srt', 'ass'].includes(item.name.substring(item.name.length - 3, item.name.length)) && !track.hasLyrics) {
                                                cleanedFilesArray.splice(i, 1, null); //удаляем файл из массива
                                                const bodyLyrics = new FormData();
                                                bodyLyrics.append('lyricsFile', item);
                                                track['stateLoading'] = 'inProgress';
                                                axios.post(`${url}/songs/${track.id}/lyrics`,
                                                    bodyLyrics, {
                                                          headers: {
                                                                Authorization: 'Bearer ' + token,
                                                          },
                                                    })
                                                    .then(response => {
                                                          // console.log(response);
                                                          track['stateLoading'] = 'done';
                                                          track['changes'] = false;
                                                          track['hasLyrics'] = true;
                                                          this.setState({
                                                                existingTrack: initialArray,
                                                                formError: '',
                                                          });
                                                    })
                                                    .catch(error => {
                                                          // console.log(JSON.stringify(error.response));
                                                          this.setState({
                                                                loading: false,
                                                                formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                                                          });
                                                    });
                                          } else if (item.name.substring(item.name.length - 3, item.name.length) === 'mp3') {
                                                cleanedFilesArray.splice(i, 1, null);
                                                this.setState({
                                                      tracksError: 'Attention! Some files were not uploaded since tracks with the same name already exist in the collection.'
                                                });
                                                this.setState({
                                                      existingTrack: initialArray,
                                                });
                                          } else {
                                                this.setState({
                                                      tracksError: 'Attention! Some files were not uploaded since tracks with the same name already exist in the collection.'
                                                });
                                                cleanedFilesArray.splice(i, 1, null);
                                          }
                                    }
                              });
                              this.setState({
                                    existingTrack: initialArray,
                              });
                        });
                        let that = this;
                        setTimeout(function () {
                              let arrayForPairs = []
                              cleanedFilesArray.forEach((item, i) => {
                                    if (item !== null) {
                                          arrayForPairs.push(item)
                                    }
                              });
                              that.createPairs(arrayForPairs);
                        }, 25);
                  } else {
                        this.setState({
                              existingTrack: initialArray,
                              tracksArray: initialArray,
                              changes: false,
                              tracksDownloaded: true,
                        });
                  }
            } else {
                  this.createPairs(filesArray);
            }
      };

      createPairs = (filesArray) => {
            let tracksArray = [];
            let index = 0;
            if (filesArray.length > 0) {
                  //here we sort new files to pairs with names
                  filesArray.forEach((item) => {
                        let done = false;
                        if (tracksArray.length === 0) {
                              if (item.type.startsWith('audio')) {
                                    tracksArray.push({
                                          files: [item],
                                          name: item.name.substring(0, item.name.indexOf('.')),
                                          mp3: true
                                    });
                                    index++;
                                    return
                              } else {
                                    tracksArray.push({
                                          files: [item],
                                          name: item.name.substring(0, item.name.indexOf('.')),
                                          mp3: false,
                                          hasLyrics: true,
                                    });
                                    index++;
                                    return
                              }

                        }

                        tracksArray.forEach((track) => {
                              if (item.name.substring(0, item.name.indexOf('.')) === track.files[0].name.substring(0, track.files[0].name.indexOf('.'))) {
                                    if (item.type.startsWith('audio')) {
                                          track.files.push(item);
                                          track['mp3'] = true;
                                          done = true;
                                    } else {
                                          track.files.push(item);
                                          done = true;
                                          track['hasLyrics'] = true;
                                    }

                              }
                        });

                        if (!done) {
                              if (item.type.startsWith('audio')) {
                                    tracksArray.push({
                                          files: [item],
                                          name: item.name.substring(0, item.name.indexOf('.')),
                                          mp3: true
                                    });
                                    index++;
                                    return
                              } else {
                                    tracksArray.push({
                                          files: [item],
                                          name: item.name.substring(0, item.name.indexOf('.')),
                                          mp3: false,
                                          hasLyrics: true,
                                    });
                                    index++;
                                    return
                              }
                        }
                  });

                  if (tracksArray.some(el => el.mp3 === false)) {
                        this.setState({
                              tracksArray: tracksArray,
                              mp3blocker: true,
                        });
                  } else {
                        let that = this;
                        setTimeout(function () {
                              if (that.state.existingTrack.length > 0) {
                                    let startArray = that.state.existingTrack;
                                    let newArray = startArray.concat(tracksArray);
                                    that.setState({
                                          tracksArray: newArray,
                                    });
                              } else {
                                    that.setState({
                                          tracksArray: tracksArray,
                                    });
                              }
                        }, 25);
                  }
            } else {
                  let existingTrack = this.state.existingTrack;
                  this.setState({
                        changes: false,
                        tracksDownloaded: true,
                        tracksArray: existingTrack,
                  });
            }

      };

      addImage = (image, index) => {
            let tracksArray = this.state.tracksArray;
            tracksArray[index].image = image;
            tracksArray[index].imageId = null;
            tracksArray[index].changes = true;
            tracksArray[index].stateLoading = false;
            this.setState({
                  tracksArray,
                  changes: true
            });
      };

      deleteImage = (index) => {
            let tracksArray = this.state.tracksArray;
            tracksArray[index].changes = true;
            tracksArray[index].stateLoading = false;
            tracksArray[index].imageId = null;
            delete tracksArray[index].image;
            this.setState({
                  tracksArray,
                  changes: true
            });
      };

      _handleUserInput = (name, event, index) => {
            let value = event.target.value;
            let tracksArray = this.state.tracksArray;
            if (event.target.value.charAt(0) === ' ') {
                  return;
            }
            if (value.length <= 255) {
                  tracksArray[index].name = value;
                  tracksArray[index].changes = true;
                  tracksArray[index].stateLoading = false;
                  this.setState({tracksArray, changes: true,})
            }
      };

      deleteTrack = () => {
            this.setState({
                  loading: true,
            });
            let tracksArray = this.state.tracksArray;
            let index = this.state.indexForDelete;
            if (tracksArray[index].songId) {
                  let token = localStorage.getItem('accessToken');
                  axios.delete(`${url}/songs/${tracksArray[index].songId}`,
                      {
                            headers: {
                                  Authorization: 'Bearer ' + token,
                            },
                      })
                      .then(response => {
                            // console.log(response);
                            tracksArray.splice(index, 1);
                            this.setState({
                                  tracksArray,
                                  formError: '',
                                  loading: false,
                                  modalForDelete: false,
                            });
                            if (tracksArray.length === 0) {
                                  this.props.history.goBack()
                            }
                      })
                      .catch(error => {
                            // console.log(JSON.stringify(error.response));
                            this.setState({
                                  loading: false,
                                  modalForDelete: false,
                                  formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                            });
                            if (error.response.status === 401) {
                                  refreshToken(this.deleteTrack);
                            }
                      });
            } else {
                  tracksArray.splice(index, 1);
                  this.setState({
                        tracksArray,
                        loading: false,
                        modalForDelete: false,
                  });
                  if (tracksArray.length === 0) {
                        this.props.history.goBack()
                  }
            }
      };

      createMediaElements = () => {
            this.props.collectionFiles([]);
            this.setState({
                  loadingTracks: true,
            });
            let token = localStorage.getItem('accessToken');
            let array = this.state.tracksArray;

            //Here we create new media elements (tracks images) and save their ID in our array
            array.forEach((item, i) => {
                  if (item.image !== undefined && item.image !== '' && item.image !== null && !item.imageId) {
                        item['stateLoading'] = 'inProgress';
                        const body = new FormData();
                        body.append('file', item.image);
                        axios.post(`${url}/media`,
                            body, {
                                  headers: {
                                        Authorization: 'Bearer ' + token,
                                  },
                            })
                            .then(response => {
                                  // console.log(response);
                                  item.imageId = response.data.media.id;
                                  item.image = response.data.media;
                                  this.createSongsList(item);
                                  this.setState({
                                        formError: '',
                                  })
                            })
                            .catch(error => {
                                  // console.log(JSON.stringify(error.response));
                                  this.setState({
                                        loading: false,
                                        formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                                  });
                            });
                  } else {
                        this.createSongsList(item);
                  }
            });
      };

      createSongsList = (item) => {
            let token = localStorage.getItem('accessToken');
            let array = this.state.tracksArray;
            let index = array.findIndex(i => i.name === "name");
            //Here we create new songs and save their ID in our array
            if (item.songId && item.changes) {
                  const bodyForPut = {};
                  if (item.name.length > 0) {
                        bodyForPut['title'] = item.name;
                  } else {
                        bodyForPut['title'] = item.files[0].name.substring(0, item.files[0].name.indexOf('.'));
                  }
                  if (item.imageId) {
                        bodyForPut['imageId'] = item.imageId;
                  } else {
                        bodyForPut['imageId'] = null;
                  }

                  item['stateLoading'] = 'inProgress';
                  axios.put(`${url}/songs/${item.songId}`,
                      bodyForPut, {
                            headers: {
                                  Authorization: 'Bearer ' + token,
                            },
                      })
                      .then(response => {
                            // console.log(response);
                            item['stateLoading'] = 'done';
                            item['changes'] = false;
                            array.splice(index, item);
                            this.setState({
                                  tracksArray: array,
                                  formError: '',
                            });
                            if (array.every(elem => elem.songId) && array.every(elem => !elem.changes)) {
                                  this.setState({
                                        loadingTracks: false,
                                        changes: false,
                                        tracksDownloaded: true,
                                  })
                            }
                      })
                      .catch(error => {
                            // console.log(JSON.stringify(error.response));
                            this.setState({
                                  loading: false,
                                  formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                            });
                      });
            } else if (item.songId === undefined) {
                  item['stateLoading'] = 'inProgress';
                  const body = new FormData();
                  item.files.forEach((file, i) => {
                        if (file.type.startsWith('audio')) {
                              body.append('file', file);
                        } else {
                              body.append('lyricsFile', file);
                        }
                  });
                  if (item.name.length > 0) {
                        body.append('title', item.name);
                  } else {
                        body.append('title', item.files[0].name.substring(0, item.files[0].name.indexOf('.')));
                  }

                  if (item.imageId) {
                        body.append('imageId', item.imageId);
                  }

                  axios.post(`${url}/songs`,
                      body, {
                            headers: {
                                  Authorization: 'Bearer ' + token,
                            },
                      })
                      .then(response => {
                            // console.log(response);
                            item.songId = response.data.song.id;
                            item['stateLoading'] = 'done';
                            item['changes'] = false;
                            array.splice(index, item);
                            this.setState({
                                  tracksArray: array,
                            });
                            if (array.every(elem => elem.songId) && array.every(elem => !elem.changes)) {
                                  this.setState({
                                        loadingTracks: false,
                                        changes: false,
                                        tracksDownloaded: true,
                                  })
                            }
                      })
                      .catch(error => {
                            // console.log(JSON.stringify(error.response));
                            this.setState({
                                  loading: false,
                                  formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                            });
                      });
            } else {
                  this.setState({
                        changes: false,
                        tracksDownloaded: true,
                  })
            }
      };

      createCollection = () => {
            this.setState({
                  loadingCollection: true,
            });
            //Here we create a body for the collection request
            const {collection} = this.props.music;
            const collectionBody = {};
            let semestersArray = [];
            let token = localStorage.getItem('accessToken');
            let arrayTracks = this.state.tracksArray;
            let tracksArray = [];

            collectionBody['title'] = collection.title;
            collection.semesters.forEach((item) => {
                  semestersArray.push(item.id);
            });
            collectionBody['semesters'] = semestersArray;
            if (collection.image) {
                  collectionBody['imageId'] = collection.imageId;
            } else {
                  collectionBody['imageId'] = null;
            }

            if (arrayTracks.length === 0) {
                  this.editCollection(collectionBody);
                  return;
            }

            arrayTracks.forEach((item, i) => {
                  let obj = {
                        id: item.songId
                  };
                  tracksArray.push(obj);

                  if (i === arrayTracks.length - 1) {
                        collectionBody['songs'] = tracksArray;
                        if (this.props.music.mode === 'new') {
                                    axios.post(`${url}/collections`,
                                  collectionBody, {
                                        headers: {
                                              Authorization: 'Bearer ' + token,
                                        },
                                  })
                                  .then(response => {
                                        // console.log(response.data);
                                        this.setState({
                                              loadingCollection: false,
                                        });
                                        this.props.activeCollection(response.data.collection);
                                        this.props.activeCollectionID(response.data.collection.id);
                                        this.props.history.push(`/music-management/${response.data.collection.id}/files-list`)
                                  })
                                  .catch(error => {
                                        // console.log(JSON.stringify(error.response));
                                        this.setState({
                                              loading: false,
                                              formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                                        });
                                  });
                        } else {
                              this.editCollection(collectionBody)
                        }
                  }
            });
      };

      editCollection = (body) => {
            const {activeCollectionId} = this.props.music;
            let token = localStorage.getItem('accessToken');

            axios.put(`${url}/collections/${activeCollectionId}`,
                body, {
                      headers: {
                            Authorization: 'Bearer ' + token,
                      },
                })
                .then(response => {
                      // console.log(response.data);
                      this.setState({
                            loadingCollection: false,
                      });
                      this.props.history.push(`/music-management/${activeCollectionId}/files-list`)
                })
                .catch(error => {
                      // console.log(JSON.stringify(error.response));
                      this.setState({
                            loadingCollection: false,
                            formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                      });
                });
      };

      tracksActions = () => {
            if (!this.state.tracksDownloaded || this.state.changes) {
                  this.createMediaElements()
            } else {
                  this.createCollection()
            }
      };

      modalForDelete = (index) => {
            this.setState({
                  modalForDelete: true,
                  indexForDelete: index,
            })
      };

      render() {
            let {collectionTitle} = this.props.music;
            let collectionName = collectionTitle.length > 20 ? (collectionTitle).substring(0, 20) + '...' : collectionTitle;
            return (
                <div className='page-container'>
                      <PageTitle
                          title={this.props.music.mode === 'edit' ? `Edit collection - ${collectionName}` : `New collection - ${collectionName}`}
                          backButton={true} backFunction={() => this.setState({modal: true, fromHeader: true,})}
                          history={this.props.history} path='/music-management'/>

                      <div className='content-block import-page'>
                            <StepsComponent steps={steps}/>
                            {this.state.loading ? <LoaderComponent loading={this.state.loading}/> :
                                <div className='tracks-container'>
                                      <p className={this.state.tracksError !== '' ? 'tracks-error' : 'tracks-error hide'}>
                                            <div>
                                                  <SVGIcon name="error" width='16'
                                                           fill='#ED1566'/><span>{this.state.tracksError}</span></div>
                                            <div onClick={() => this.setState({tracksError: ''})}
                                                 className='first-button'>Ok
                                            </div>
                                      </p>
                                      {this.state.loadingTracks && <div className='tracks-blocker'></div>}
                                      {this.state.mp3blocker && <div className='mp3-blocker'>
                                            <p>Each track should include an mp3 file. Please back to the previous
                                                  step</p>
                                            <div className='first-button'
                                                 onClick={() => this.props.history.goBack()}>Back
                                            </div>
                                      </div>}
                                      {this.state.tracksArray.length > 0 && this.state.tracksArray.map((track, i) => {
                                            return (
                                                <TrackComponent
                                                    ref={track => this.track[i] = track}
                                                    item={track}
                                                    key={i}
                                                    addImage={image => this.addImage(image, i)}
                                                    deleteImage={() => this.deleteImage(i)}
                                                    deleteTrack={() => this.modalForDelete(i)}
                                                    localId={i}
                                                    onChangeName={event => this._handleUserInput('name', event, i)}/>
                                            )
                                      })}
                                      {this.state.tracksArray.length === 0 &&
                                      <p style={{textAlign: 'center', marginTop: '40px'}}>Please, choose files
                                            first</p>
                                      }
                                </div>
                            }
                            <p className={this.state.formError !== '' ? 'form-error' : 'form-error hide'}>
                                  <SVGIcon name="error" width='16'
                                           fill='#ED1566'/><span>{this.state.formError}</span></p>
                            <div className='content-footer two-buttons'>
                                  <button className='fourth-button'
                                          onClick={() => {
                                                this.setState({
                                                      modal: true,
                                                })
                                          }}>Back
                                  </button>
                                  <div className='loader-with-button'>
                                        {this.state.changes && <button
                                            className={this.state.loadingTracks || this.state.loadingCollection || this.state.mp3blocker ? 'first-button disabled' : 'first-button'}
                                            onClick={this.tracksActions}>Next
                                        </button>
                                        }
                                        {this.state.loadingCollection &&
                                        <LoaderComponent loading={this.state.loadingCollection}/>}
                                        {this.state.tracksDownloaded && !this.state.changes && <button
                                            className={this.state.loadingTracks || this.state.loadingCollection  ? 'first-button disabled' : 'first-button'}
                                            onClick={this.tracksActions}>Save collection</button>
                                        }
                                  </div>
                            </div>
                            {this.state.modal && <ModalLeavePage
                                leave={() => this.state.fromHeader ? this.props.history.push('/music-management') : this.props.history.goBack()}
                                no={() => {
                                      this.setState({modal: false, fromHeader: false})
                                }}/>}
                            {this.state.modalForDelete && <ModalDeleteCollectionTrack loading={this.state.loading}
                                                                             deleteTrack={this.deleteTrack}
                                                                             closeModal={() => this.setState({modalForDelete: false})}/>}

                      </div>
                </div>
            );
      }
}


const mapStateToProps = state => {
      return {
            files: state.files,
            music: state.music,
      };
};

export default withRouter(connect(mapStateToProps, {
      collectionFiles,
      activeCollection,
      activeCollectionID
})(MusicManagementStep2_2));

