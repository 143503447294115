import React from 'react';
import Loader from "react-loader";


let options = {
      lines: 14,
      length: 5,
      width: 7,
      radius: 5,
      scale: 1.00,
      corners: 1,
      color: '#03BAAE',
      opacity: 0.25,
      rotate: 0,
      direction: 1,
      speed: 2,
      trail: 50,
      fps: 0,
      zIndex: 2e9,
      shadow: false,
      hwaccel: true,
      position: 'relative',
};

export default function LoaderComponent({loading}) {
      return <Loader loaded={!loading} options={options} />
}
