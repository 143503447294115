import React, {Component} from 'react';
import SVGIcon from "../../../components/svgIcon";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {collectionMode, activeCollectionID, trackArray, activeCollection} from "../../../actions";
import axios from "axios";
import refreshToken from "../../../api/refreshToken";
import Pagination from "../../../components/pagination";

import placeholder from '../../../images/placeholder.png';
import {url} from "../../../api/api";

class MusicCollection extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  opened: false,
                  songs: [],
                  loading: false,
                  formError: '',
                  total: 0,
                  perPage: 0,
                  page: 1,
            }
      }

      componentDidMount() {
            this.getData();
      }

      getData = () => {
            this.setState({
                  loading: true,
            });
            let token = localStorage.getItem('accessToken');
            let path = `${url}/collections/${this.props.item.id}/songs?`;

            if (this.state.page > 1) {
                  path += '&page=' + this.state.page
            }
            if (this.props.search) {
                  path += '&term=' + this.props.search;
            }

            axios.get(path,
                {
                      headers: {
                            Authorization: 'Bearer ' + token,
                      },
                })
                .then(response => {
                      // console.log(response);
                      this.setState({
                            formError: '',
                            songs: response.data.songs,
                            total: response.data.meta.total,
                            perPage: response.data.meta.perPage,
                      });
                      if ((this.props.search && response.data.songs.length > 0) || this.state.opened === true) {
                            this.setState({
                                  opened: true,
                            })
                      } else {
                            this.setState({
                                  opened: false,
                            })
                      }
                })
                .catch(error => {
                      // console.log(JSON.stringify(error));
                      this.setState({
                            loading: false,
                            formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                      });
                      if (error.response.status === 401) {
                            refreshToken(this.getData);
                      }
                });
      };

      str_pad_left = (time) => {
            let minutes = Math.floor(time / 60);
            let seconds = time - minutes * 60;
            if ( minutes < 10) {
                  minutes = '0' + minutes
            }
            if ( seconds < 10) {
                  seconds = '0' + seconds
            }
            return `${minutes}:${seconds}`;
      };

      onChangePage = (page) => {
            this.setState(state => {
                  return {
                        ...state,
                        page: page,
                  }
            }, this.getData)
      };

      getCollectionData(id) {
            let token = localStorage.getItem('accessToken');
            axios.get(`${url}/collections/${id}`,
                {
                      headers: {
                            Authorization: 'Bearer ' + token,
                      },
                })
                .then(response => {
                      // console.log(response);
                      this.setState({
                            formError: '',
                      });
                      this.props.activeCollection(response.data.collection);
                      this.props.trackArray(response.data.collection.songs);
                      this.props.history.push(`/music-management/${id}/edit-collection`);
                })
                .catch(error => {
                      // console.log(JSON.stringify(error));
                      this.setState({
                            loading: false,
                            formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                      });
                      if (error.response.status === 401) {
                            refreshToken(() => this.getCollectionData(id));
                      }
                });
      }

      render() {
            const {title, id, image, semesters} = this.props.item;
            const {opened, songs, total, perPage, page} = this.state;
            return (
                <div className={opened ? 'collection-block' : 'collection-block closed'}>
                      <div className='music-collection-header'>
                            <div className='left-block'>
                                  <div onClick={() => this.setState((prevState) => ({opened: !prevState.opened}))}
                                       className='open-line-link'>
                                        {opened ?
                                            <SVGIcon name='chevron-top' width='10' className='chevron-icon'/> :
                                            <SVGIcon name='chevron-bottom' width='10' className='chevron-icon'/>}
                                  </div>
                                  {image ? <img src={image.path} alt=''
                                                className='collection-image' width='76'/> :
                                      <img src={placeholder} alt=''
                                           className='collection-image' width='76'/>}

                                  <div>
                                        <p className='music-collection-name-title'>Collection title</p>
                                        <p className='music-collection-name'>{title}</p>
                                  </div>
                            </div>
                            <div>
                                  <p className='music-collection-name-title'>ACTIONS</p>
                                  <div className='actions-block'>
                                        <div onClick={() => {
                                              this.getCollectionData(id);
                                              this.props.collectionMode('edit');
                                              this.props.activeCollectionID(id);
                                        }}><SVGIcon name='edit'
                                                    width='18'
                                                    className='icon'/></div>
                                        <div onClick={() => this.props.chooseCollection(id)}><SVGIcon name='trash'
                                                                                                      width='14'
                                                                                                      className='icon'/>
                                        </div>
                                  </div>
                            </div>
                      </div>
                      <div className='collection-body'>
                            <table className='collection-table music-collection'>
                                  <thead>
                                  <tr>
                                        <td>#</td>
                                        <td>song title</td>
                                        <td>Image</td>
                                        <td>Lyrics</td>
                                        <td>duration</td>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  {songs.map((item, i) => {
                                        return <tr key={i}>
                                              <td>{(page - 1) * 15 + i + 1}</td>
                                              <td>{item.title}</td>
                                              <td>{item.image ? <img src={item.image.path} alt=''
                                                                     className='line-image' width='40'/> :
                                                  <p>No image</p>}</td>
                                              <td>{item.hasLyrics ? <SVGIcon name='success' width='16' height='16'
                                                                             fill='#03BAAE'/> : 'No lyrics'}</td>
                                              <td>{this.str_pad_left(item.duration)}</td>
                                        </tr>
                                  })}
                                  </tbody>
                            </table>
                            <p className={this.state.formError !== '' ? 'form-error' : 'form-error hide'}>
                                  <SVGIcon name="error" width='16' height='16'
                                           fill='#ED1566'/><span>{this.state.formError}</span></p>
                      </div>
                      <div className='collection-footer two-buttons'>
                            <div>
                                  <p className="music-collection-name-title"> Collection/semester assigned</p>
                                  {semesters.map((item, i) => {
                                        return <p className='music-collection-name' key={i}>{item.name}</p>
                                  })}
                            </div>
                            <Pagination total={total}
                                        perPage={perPage}
                                        page={page}
                                        onChangePage={this.onChangePage}
                            />
                      </div>
                </div>
            )
      }
}


const mapStateToProps = state => {
      return {
            files: state.files
      };
};

export default withRouter(connect(mapStateToProps, {collectionMode, activeCollectionID, trackArray, activeCollection})(MusicCollection));

