import React, {useState} from 'react';
import InputText from "../../../components/inputs/input-text";
import SVGIcon from "../../../components/svgIcon";


export default function LinksComponent({item, onChange, number, deleteLink}) {
      return (
          <div className='link-component'>
                <div className='info-form-block'>
                      <span className='number'>{`${number + 1}.`}</span>
                      <div className='links-block'>
                            <InputText
                                label='link’s name'
                                placeholder='Type the link’s name'
                                value={item.name}
                                onChange={event => {
                                      onChange('name', event, number)
                                }}
                                defaultValue={item.name}
                                name='name'
                                error={item.errorName === ''}
                                errorText={item.errorName}
                            />
                            <InputText
                                label='link'
                                placeholder='Type link address'
                                value={item.url}
                                onChange={event => {
                                      onChange('url', event, number)
                                }}
                                defaultValue={item.url}
                                name='url'
                                error={item.errorLink === ''}
                                errorText={item.errorLink}
                            />
                      </div>
                      <div className={((item.name || item.url) && number !== 0) ? 'delete-link' : 'delete-link hide'}
                           onClick={deleteLink}><SVGIcon name="error"
                                                         width='20'
                                                         height='20' fill='#000'/></div>
                </div>
                <p className={item.error !== '' ? 'form-error' : 'form-error hide'}>
                      <SVGIcon name="error" width='16'
                               fill='#ED1566'/><span>{item.error}</span></p></div>

      )
}

