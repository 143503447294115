import {
    AUTHORIZE,
    IMPORT_FILES,
    COLLECTION_MODE,
    MUSIC_FILES,
    ACTIVE_COLLECTION_ID,
    ACTIVE_COLLECTION,
    TRACK_ARRAY,
    COLLECTION_TITLE,
    NOTIFICATION_MODE,
    ACTIVE_NOTIFICATION_ID,
    ACTIVE_NOTIFICATION,
    NOTIFICATION_TITLE,
    NOTIFICATION_TYPE,
    NOTIFICATION_ARCHIVED,
    NOTIFICATION_FOR_COMPARE
} from './types';

export function authorize(user) {
    return {
        type: AUTHORIZE,
        payload: user,
    }
}

export function importFiles(files, uuid) {
    return {
        type: IMPORT_FILES,
        payload: {files, uuid},
    }
}

export function collectionMode(mode) {
    return {
        type: COLLECTION_MODE,
        payload: mode,
    }
}

export function collectionFiles(files) {
    return {
        type: MUSIC_FILES,
        payload: files,
    }
}

export function activeCollectionID(id) {
    return {
        type: ACTIVE_COLLECTION_ID,
        payload: id,
    }
}

export function activeCollection(obj) {
    return {
        type: ACTIVE_COLLECTION,
        payload: obj,
    }
}

export function trackArray(arr) {
    return {
        type: TRACK_ARRAY,
        payload: arr,
    }
}

export function collectionTitle(string) {
    return {
        type: COLLECTION_TITLE,
        payload: string,
    }
}


export function notificationMode(string) {
    return {
        type: NOTIFICATION_MODE,
        payload: string,
    }
}

export function activeNotificationID(id) {
    return {
        type: ACTIVE_NOTIFICATION_ID,
        payload: id,
    }
}

export function activeNotification(obj) {
    return {
        type: ACTIVE_NOTIFICATION,
        payload: obj,
    }
}

export function notificationTitle(string) {
    return {
        type: NOTIFICATION_TITLE,
        payload: string,
    }
}

export function notificationType(type) {
    return {
        type: NOTIFICATION_TYPE,
        payload: type,
    }
}

export function notificationArchived(type) {
    return {
        type: NOTIFICATION_ARCHIVED,
        payload: type,
    }
}

export function notificationForCompare(type) {
    return {
        type: NOTIFICATION_FOR_COMPARE,
        payload: type,
    }
}


