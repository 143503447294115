import React from 'react';

export default function StepsComponent({steps, two}) {
      return <div className='steps-component-wrapper'>
            <div className={two ? 'steps-component two' : 'steps-component'}>
                  {steps.map((item, i) => {
                        return <div key={i} className='step-wrapper'>
                              <div className={item.active ? 'step-circle active' : 'step-circle'}>{i + 1}</div>
                              <p className={item.active ? 'step-name active' : 'step-name'}>{item.name}</p>
                        </div>
                  })}
            </div>
      </div>
}
