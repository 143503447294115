import axios from 'axios';
import customHistory from '../pages/history';
import { url } from './api';


const refreshToken = function (func) {
      const token = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');
      axios.post(`${url}/auth/refresh`,
          {
                refreshToken: refreshToken,
          },
          {
                headers: {
                      Authorization: 'Bearer ' + token,
                }
          })
          .then(response => {
                // console.log(response);
                let {accessToken, refreshToken} = response.data;
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);
                func();
          })
          .catch(error => {
                // console.log(JSON.stringify(error.response));
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                customHistory.push('/');
          })
};

export default refreshToken;
