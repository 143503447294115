import React, {useState} from 'react';
import SVGIcon from '../components/svgIcon';
import Logo from '../images/logo.png';
import {
      useRouteMatch,
      useLocation
} from "react-router-dom";
import ModalLeavePage from "../pages/musicManagement/components/modalForLeave";
import customHistory from '../pages/history';

export default function NavBar() {
      const [modal, setModal] = useState(false);
      const [route, setRoute] = useState('/music-management');
      let location = useLocation();

      function click(route) {
            if (location.pathname !== '/import-data' && location.pathname !== '/notifications-management' && location.pathname !== '/students-management' && location.pathname !== '/music-management') {
                  setModal(true);
                  setRoute(route);
            } else {
                  customHistory.push(route)
            }
      }

      return <div className='nav-bar'>
            <div onClick={() => click('/music-management')} className='nav-bar-image-link'>
                  <img src={Logo} alt='' className='nav-bar-image' width='123'/>
            </div>
            <div onClick={() => click('/music-management')}
                 className={useRouteMatch('/music-management') ? 'nav-bar-item active' : 'nav-bar-item'}>
                  <div className='back-for-icon'><SVGIcon name="music" width='14' fill='#fff'/></div>
                  <span>Music management</span></div>
            <div onClick={() => click('/students-management')}
                 className={useRouteMatch('/students-management') ? 'nav-bar-item active' : 'nav-bar-item'}>
                  <div className='back-for-icon'><SVGIcon name="students" width='18' fill='#fff'/></div>
                  <span>Students management</span></div>
            <div onClick={() => click('/notifications-management')}
                 className={useRouteMatch('/notifications-management') ? 'nav-bar-item active' : 'nav-bar-item'}>
                  <div className='back-for-icon'><SVGIcon name="notifications" width='14' fill='#fff'/></div>
                  <span>Notifications management</span></div>
            <div onClick={() => click('/import-data')}
                 className={useRouteMatch('/import-data') ? 'nav-bar-item active' : 'nav-bar-item'}>
                  <div className='back-for-icon'><SVGIcon name="import" width='14' fill='#fff'/></div>
                  <span>Import data</span></div>
            <div onClick={() => click('/info-page-management')}
                 className={useRouteMatch('/info-page-management') ? 'nav-bar-item active' : 'nav-bar-item'}>
                  <div className='back-for-icon'><SVGIcon name="info" width='14' fill='#fff'/></div>
                  <span>Info page management</span></div>
            {modal && <ModalLeavePage
                leave={() => {customHistory.push(route); setModal(false)}}
                no={() => {
                      setModal(false);
                }}/>}
      </div>
}
