import MusicManagementIndex from "../pages/musicManagement/musicManagement-index";
import MusicManagementStep1 from "../pages/musicManagement/musicManagement-step1";
import MusicManagementStep2 from "../pages/musicManagement/musicManagement-step2";
import MusicManagementStep2_2 from "../pages/musicManagement/musicManagement-step2-2";
import MusicManagementStep3 from "../pages/musicManagement/musicManagement-step3";
import MusicManagementSuccess from "../pages/musicManagement/musicManagement-success";


export const musicRoutes = [
      {
            path: '/music-management',
            exact: true,
            component: MusicManagementIndex,
      },
      {
            path: '/music-management/new-collection/id',
            component: MusicManagementStep1,
      },
      {
            path: '/music-management/:collectionId/edit-collection',
            component: MusicManagementStep1,
      },
      {
            path: '/music-management/new-collection/add-files',
            component: MusicManagementStep2,
      },
      {
            path: '/music-management/:collectionId/add-files',
            component: MusicManagementStep2,
      },
      {
            path: '/music-management/new-collection/uploading',
            component: MusicManagementStep2_2,
      },
      {
            path: '/music-management/:collectionId/uploading',
            component: MusicManagementStep2_2,
      },
      {
            path: '/music-management/:collectionId/files-list',
            component: MusicManagementStep3,
      },
      {
            path: '/music-management/new-collection/success',
            component: MusicManagementSuccess,
      },
      {
            path: '/music-management/:collectionId/success',
            component: MusicManagementSuccess,
      },
];
