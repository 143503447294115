import React, {Component} from 'react';
import SVGIcon from "../../../components/svgIcon";

class ModalLeavePage extends Component {
      render() {
            return (
                <div className='modal-wrapper'>
                      <div className='modal-block'>
                            <p className='modal-title'>Leave page</p>
                            <p className='modal-text'>Are you sure you want to leave this page? Any changes will not be saved.</p>
                            <div className='modal-button-block'>
                                  <button className='first-button' onClick={this.props.no}>Stay on this page</button>
                                  <button className='third-button' onClick={this.props.leave}>Leave page</button>
                            </div>
                            <div className='close-modal' onClick={this.props.no}><SVGIcon name='error'
                                                                                                      width='20'
                                                                                                      height='20'/>
                            </div>
                      </div>
                </div>
            );
      }
}

export default ModalLeavePage;
