import React, {Component} from 'react';
import SVGIcon from "../../../components/svgIcon";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

class ModalCropImage extends Component {
      constructor(props) {
            super(props);

            this.state = {
                  src: null,
                  crop: {
                        unit: '%',
                        width: 40,
                        aspect: 1 / 1,
                  }
            }
      }

      componentDidMount() {
            this.onSelectFile(this.props.file);
      }

      onSelectFile = e => {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({src: reader.result})
            );
            reader.readAsDataURL(e);
      };

      onImageLoaded = image => {
            this.imageRef = image;
      };

      onCropComplete = crop => {
            this.makeClientCrop(crop);
      };

      onCropChange = (crop) => {
            this.setState({crop});
      };

      async makeClientCrop(crop) {
            if (this.imageRef && crop.width && crop.height) {
                  const croppedImageUrl = await this.getCroppedImg(
                      this.imageRef,
                      crop,
                      'newFile.jpeg'
                  );
                  this.setState({croppedImageUrl});
            }
      }

      getCroppedImg(image, crop, fileName) {
            const canvas = document.createElement('canvas');
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext('2d');

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );

            const reader = new FileReader();
            return new Promise((resolve, reject) => {
                  canvas.toBlob(blob => {
                        if (!blob) {
                              console.error('Canvas is empty');
                              return;
                        }
                        blob.name = fileName;
                        window.URL.revokeObjectURL(this.fileUrl);
                        this.fileUrl = window.URL.createObjectURL(blob);
                        reader.readAsDataURL(blob);
                        reader.onloadend = () => {
                              this.dataURLtoFile(reader.result, 'cropped.jpg')
                        };
                        resolve(this.fileUrl);
                  }, 'image/jpeg');
            });
      }

      dataURLtoFile(dataurl, filename) {
            let arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while(n--){
                  u8arr[n] = bstr.charCodeAt(n);
            }
            let croppedImage = new File([u8arr], filename, {type:mime});
            this.setState({croppedImage: croppedImage })
      }

      render() {
            const {crop, src, croppedImage} = this.state;
            return (
                <div className='modal-wrapper'>
                      <div className='modal-block-image'>
                            <p className='modal-title'>Please, crop your image</p>
                            <ReactCrop
                                src={src}
                                crop={crop}
                                ruleOfThirds
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                            />
                            <div className='modal-button-block'>
                                  <button className='first-button' onClick={() => this.props.onPush(croppedImage)}>Done</button>
                                  <button className='third-button' onClick={() => this.props.closeModal()}>Cancel
                                  </button>
                            </div>
                            <div className='close-modal' onClick={() => this.props.closeModal()}><SVGIcon name='error'
                                                                                                          width='20'
                                                                                                          height='20'/>
                            </div>
                      </div>
                </div>
            );
      }
}

export default ModalCropImage;
