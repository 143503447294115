import React, {Component} from 'react';
import SVGIcon from "./svgIcon";
import LoaderComponent from "../pages/musicManagement/components/loader";

class ModalForLogout extends Component {
      render() {
            return (
                <div className='modal-wrapper'>
                      <div className='modal-block'>
                            <p className='modal-title'>Log out</p>
                            <p className='modal-text'>Are you sure you want to log out?</p>
                            {this.props.loading && <LoaderComponent loading={this.props.loading}/>}
                            <div className='modal-button-block'>
                                  <button className='first-button' onClick={() => this.props.onPush()}>Yes</button>
                                  <button className='third-button' onClick={() => this.props.closeModal()}>No</button>
                            </div>
                            <div className='close-modal' onClick={() => this.props.closeModal()}><SVGIcon name='error'
                                                                                                          width='20'
                                                                                                          height='20'/>
                            </div>
                      </div>
                </div>
            );
      }
}

export default ModalForLogout;
