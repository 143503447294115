import {
    NOTIFICATION_MODE,
    ACTIVE_NOTIFICATION_ID,
    ACTIVE_NOTIFICATION,
    NOTIFICATION_TITLE,
    NOTIFICATION_TYPE,
    NOTIFICATION_ARCHIVED,
    NOTIFICATION_FOR_COMPARE
} from "../actions/types";

const defaultState = {
    mode: 'edit',
    activeNotificationId: 0,
    notification: {},
    notificationForCompare: {},
    notificationTitle: '',
    notificationType: 1,
    notificationArchived: 0,
};

export default function notificationStates(state = defaultState, action) {
    switch (action.type) {
        case NOTIFICATION_MODE:
            return {...state, mode: action.payload};
        case ACTIVE_NOTIFICATION_ID:
            return {...state, activeNotificationId: action.payload};
        case ACTIVE_NOTIFICATION:
            return {...state, notification: action.payload};
        case NOTIFICATION_TITLE:
            return {...state, notificationTitle: action.payload};
        case NOTIFICATION_TYPE:
            return {...state, notificationType: action.payload};
        case NOTIFICATION_ARCHIVED:
            return {...state, notificationArchived: action.payload};
        case NOTIFICATION_FOR_COMPARE:
            return {...state, notificationForCompare: action.payload};
        default:
            return state
    }
}
