import React, {Component} from 'react';
import {Router, Route, Switch} from "react-router-dom";
import routes from './routes';
import customHistory from "./pages/history";
import styles from './App.scss';
import ErrorPage from './pages/404';

class App extends Component {
      render() {
            return (
                <Router history={customHistory}>
                      <Switch>
                            {routes.map((route, index) => {
                                  return (
                                      <Route
                                          key={index}
                                          path={route.path}
                                          exact={route.exact}
                                          component={(props => {
                                                return (
                                                    <route.layout {...props}>
                                                          <route.component {...props} />
                                                    </route.layout>
                                                );
                                          })}
                                      />
                                  );
                            })}
                            <Route component={ErrorPage}/>
                      </Switch>
                </Router>
            );
      }
}

export default App;
