import React, {Component} from 'react';
import PageTitle from "../../components/pageTitle";
import StepsComponent from "./components/stepsComponent";
import {connect} from "react-redux";
import {collectionFiles} from "../../actions";
import {withRouter} from 'react-router-dom';
import SVGIcon from "../../components/svgIcon";
import ModalLeavePage from "./components/modalForLeave";

const steps = [
      {
            name: 'General ',
            active: false,
      },
      {
            name: 'Songs upload',
            active: true,
      },
      {
            name: 'Songs edit',
            active: false,
      }
];

class MusicManagementStep2 extends Component {
      constructor(props) {
            super(props);

            this.state = {
                  modal: false,
            }
      }

      componentWillMount() {
            if (this.props.music.activeCollectionId === 0 && this.props.music.mode === 'edit') {
                  this.props.history.push('/music-management');
            }
      }

      onChange = (data) => {
            if (this.props.music.files.length > 0) {
                  let oldArray = this.props.music.files;
                  let newArray = oldArray.concat(data);
                  this.props.collectionFiles(newArray);
                  this.fileInput.value = "";
            } else {
                  this.props.collectionFiles(data);
                  this.fileInput.value = "";
            }
      };

      deleteFile = (index) => {
            let oldArray = this.props.music.files;
            oldArray.splice(index, 1);
            this.props.collectionFiles(oldArray);
      };


      renderName = (name) => {
            return name;
      };

      render() {
            const {activeCollectionId, files, mode, collectionTitle} = this.props.music;
            let collectionName = collectionTitle.length > 20 ? (collectionTitle).substring(0, 20) + '...' : collectionTitle;
            return (
                <div className='page-container'>
                      <PageTitle
                          title={mode === 'edit' ? `Edit collection - ${collectionName}` : `New collection - ${collectionName}`}
                          backButton={true} backFunction={() => this.setState({modal: true, fromHeader: true,})} history={this.props.history} path='/music-management'/>

                      <div className='content-block import-page'>
                            <StepsComponent steps={steps}/>
                            <div className={files.length === 0 ? 'content-wrapper' : 'content-wrapper with-files'}>
                                  <div className='files-button'>
                                        <label htmlFor='single-file'>
                                              <div className='first-button'>
                                                    Choose file to upload
                                              </div>
                                        </label>
                                        <input type='file' id='single-file' ref={ref=> this.fileInput = ref} accept=".mp3, .srt, .ass" onChange={e => {
                                              this.onChange([...e.target.files]);
                                        }} multiple/>
                                  </div>
                                  <div className='files-block'>
                                        {files.length > 0 &&
                                                  <div className='list-titles-line'>
                                                        <div
                                                            className='#'>#</div>
                                                        <div className=''>File name</div>
                                                        <div className=''>
                                                              <div>Delete</div>
                                                        </div>
                                                  </div>
                                        }

                                        {files.length > 0 && files.map((item, i) => {
                                              return (
                                                  <div className='list-track-line' key={i}>
                                                        <div
                                                            className='#'>{i + 1}</div>
                                                        <div className=''>{this.renderName(item.name)}</div>

                                                        <div className=''>
                                                              <div onClick={() => this.deleteFile(i)}><SVGIcon
                                                                  name='trash'
                                                                  width='12'
                                                                  height='15.4'
                                                                  className='icon'/></div>
                                                        </div>
                                                  </div>
                                              )
                                        })}
                                  </div>
                            </div>
                            <div className='content-footer two-buttons'>
                                  <button className='fourth-button'
                                          onClick={() => {
                                                this.setState({
                                                      modal: true,
                                                })
                                          }}>Back
                                  </button>
                                  <button
                                      className={files.length > 0 || this.props.music.mode === 'edit' ? 'first-button' : 'first-button disabled'}
                                      onClick={() => {
                                            mode === 'edit' ? this.props.history.push(`/music-management/${activeCollectionId}/uploading`) : this.props.history.push('/music-management/new-collection/uploading')
                                      }}>{this.props.music.mode === 'edit' && files.length === 0 ? 'Skip' : 'Next'}
                                  </button>
                            </div>
                            {this.state.modal && <ModalLeavePage
                                leave={() => this.state.fromHeader ? this.props.history.push('/music-management') : this.props.history.goBack()}
                                no={() => {
                                      this.setState({modal: false, fromHeader: false})
                                }}/>}
                      </div>
                </div>
            );
      }
}


const mapStateToProps = state => {
      return {
            music: state.music,
      };
};

export default withRouter(connect(mapStateToProps, {collectionFiles})(MusicManagementStep2));


