import React, {Component} from 'react';
import SVGIcon from '../components/svgIcon';
import {connect} from 'react-redux';
import InputEmail from '../components/inputs/input-email';
import InputPassword from '../components/inputs/input-password';
import axios from 'axios';
import LoaderComponent from "./musicManagement/components/loader";
import {url} from '../api/api';


class LoginPage extends Component {
      constructor(props) {
            super(props);

            this.state = {
                  emailValid: true,
                  passwordValid: true,
                  editedEmail: false,
                  editedPassword: false,

                  email: '',
                  password: '',
                  emailError: '',
                  passwordError: '',

                  formError: '',
                  loading: false,
            }
      }

      _handleUserInput = (name, event) => {
            let title = name;
            let value = event.target.value;
            if (title === 'email') {
                  this.setState({
                        editedEmail: true
                  })
            } else {
                  this.setState({
                        editedPassword: true
                  })
            }
            this.setState({[title]: value, formError: '',},
                () => {
                      this._validateField(title, value);
                });
      };

      _validateField(fieldName, value) {
            let emailError = this.state.emailError;
            let passwordError = this.state.passwordError;
            let emailValid = this.state.emailValid;
            let passwordValid = this.state.passwordValid;
            switch (fieldName) {
                  case 'email':
                        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                        emailError = emailValid ? '' : 'Email is not correct';
                        break;
                  case 'password':
                        passwordValid = value.length > 0;
                        passwordError = passwordValid ? '' : 'This field is required';
                        break;
                  default:
                        break;
            }

            this.setState({
                  emailError: emailError,
                  passwordError: passwordError,
                  emailValid: emailValid,
                  passwordValid: passwordValid,
            });
      }

      signIn = () => {
            this.setState({
                  formError: '',
                  loading: true,
            });

            axios.post(`${url}/auth/admin`,
                {
                      email: this.state.email,
                      password: this.state.password,
                })
                .then(response => {
                      // console.log(response);
                      let {accessToken, refreshToken, user} = response.data;
                      localStorage.setItem('accessToken', accessToken);
                      localStorage.setItem('refreshToken', refreshToken);
                      localStorage.setItem('user', JSON.stringify(user));
                      this.setState({
                            formError: '',
                            loading: false,
                      });
                      this.props.history.push('/')
                })
                .catch(error => {
                      // console.log(JSON.stringify(error.response));
                      this.setState({
                            loading: false,
                      });
                      if (error.response.data.message === 'An input data do not meet requirements') {
                            this.setState({
                                  formError: 'The value should be a valid email'
                            })
                      } else {
                            this.setState({
                                  formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                            })
                      }
                });
      };

      render() {
            return (
                <div className='login-container'>
                      <div className='login-wrapper'>
                            <h1>Log in</h1>
                            <form>
                                  <InputEmail
                                      label='Enter your email'
                                      placeholder='Email'
                                      value={this.state.email}
                                      onChange={event => this._handleUserInput('email', event)}
                                      defaultValue={this.state.email}
                                      name='email'
                                      error={this.state.emailValid}
                                      errorText={this.state.emailError}
                                  />
                                  <InputPassword
                                      label='Enter your password'
                                      placeholder='Password'
                                      value={this.state.password}
                                      onChange={event => this._handleUserInput('password', event)}
                                      defaultValue={this.state.password}
                                      name='password'
                                      error={this.state.passwordValid}
                                      errorText={this.state.passwordError}
                                  />
                                  <LoaderComponent loading={this.state.loading}/>
                                  <p className={this.state.formError !== '' ? 'form-error' : 'form-error hide'}>
                                        <SVGIcon name="error" width='16'
                                                 fill='#ED1566'/><span>{this.state.formError}</span></p>

                                  <div
                                      className={!this.state.emailValid || !this.state.passwordValid || !this.state.editedEmail || !this.state.editedPassword || this.state.loading ? 'first-button disabled' : 'first-button'}
                                      onClick={this.signIn}
                                  >Log in
                                  </div>
                            </form>
                      </div>
                </div>
            );
      }
}

export default connect(state => ({
      auth: state.auth
}))(LoginPage);
