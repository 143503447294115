import React from 'react';
import SVGIcon from './svgIcon';
import TopBar from "./topBar";

export default function PageTitle({
                                        title,
                                        backButton = false,
                                        history,
                                        path,
                                        backFunction
                                  }) {

      function back() {
            if (backFunction) {
                  backFunction();
            } else {
                  history.push(path)
            }
      }

      return <div className='page-title-block'>
            <div className='page-title-wrapper'>
                  {backButton &&
                  <button onClick={back} className='goBack-button'><SVGIcon name='chevron-left'
                                                                                                width='7.4'/>
                  </button>}
                  <p className='page-title'>{title}</p>
            </div>
            <TopBar/>
      </div>
}


