import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavBar from "../components/navBar";

class DefaultLayout extends Component {
      componentDidMount() {
            const token = localStorage.getItem('accessToken');
            if (!token) {
                  this.props.history.push('/login');
            }
      }

      render() {
            const { children } = this.props;
            return <div className='main-container'>
                 <NavBar/>
                     {children}
            </div>
      }

}

export default connect(state => ({
      auth: state.auth
}))(DefaultLayout)
