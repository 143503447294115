import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import SVGIcon from "../../components/svgIcon";
import PageTitle from "../../components/pageTitle";
import { connect } from "react-redux";
import axios from "axios";
import LoaderComponent from "../musicManagement/components/loader";
import refreshToken from "../../api/refreshToken";
import { url } from '../../api/api';
import { importFiles } from '../../actions';

class ImportDataUpload extends Component {
  retries = 0

  constructor(props) {
    super(props);

    this.state = {
      formError: '',
      loading: false,
    }
  }

  componentDidMount() {
    if (!this.props.uuid) {
      this.props.history.push('/import-data')
    }
  }

  onCancel = () => {
    const token = localStorage.getItem('accessToken')

    axios
      .delete(`${url}/import/${this.props.uuid}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then(() => {
        this.props.dispatch(importFiles([], null))
        this.props.history.push('/import-data')
      })
      .catch(() => {
        this.props.dispatch(importFiles([], null))
        this.props.history.push('/import-data')
      })
  }

  proceedImport = () => {
    this.setState({
      loading: true,
    })
    let token = localStorage.getItem('accessToken')

    axios
      .post(`${url}/import/${this.props.uuid}/proceed`, {}, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        this.setState({
          formError: '',
          loading: false,
        })
        this.props.history.push('/import-data/success')
        this.props.dispatch(importFiles([], null))
      })
      .catch((error) => {
        this.setState({
          loading: false,
          formError: error.response.data.message
            ? error.response.data.message
            : 'Looks like something went wrong. Please try again later.',
        })
        if (error.response.status === 401 && this.retries < 2) {
          refreshToken(this.proceedImport)
          this.retries++
        }
      })
  }

  render() {
    return (
      <div className="page-container">
        <PageTitle
          title="Import data"
          backButton={true}
          history={this.props.history}
          path="/import-data"
        />

        <div className="content-block import-page import-upload">
          <div className="content-title">Import</div>
          <div className="files-area">
            {this.props.files.files.length > 0 ? (
              this.props.files.files.map((item, i) => {
                return (
                  <div key={i} className="file-wrapper">
                    <SVGIcon
                      name="emptyFile"
                      width="20"
                      height="25"
                      fill="#666666"
                    />
                    <span className="file-name">{item.name}</span>
                  </div>
                )
              })
            ) : (
                <span>Please, choose a file first</span>
              )}
          </div>
          <LoaderComponent loading={this.state.loading} />
          <p
            className={
              this.state.formError !== ''
                ? 'form-error'
                : 'form-error hide'
            }>
            <SVGIcon
              name="error"
              width="16"
              height="16"
              fill="#ED1566"
            />
            <span>{this.state.formError}</span>
          </p>
          <div className="content-footer two-buttons">
            <button
              className="third-button"
              onClick={this.onCancel}>
              Cancel
            </button>
            <button
              className={
                this.state.loading ||
                  this.props.files.files.length === 0
                  ? 'first-button disabled'
                  : 'first-button'
              }
              onClick={this.proceedImport}>
              Finish
            </button>
          </div>
        </div>
      </div>
    )
  }

  componentWillUnmount() {
    this.props.dispatch(importFiles([], null))
  }
}

const mapStateToProps = state => {
  return {
    files: state.files,
    uuid: state.files.uuid
  };
};

export default withRouter(connect(mapStateToProps)(ImportDataUpload));
