import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom';
import {
      notificationMode,
      activeNotificationID,
      notificationType,
      notificationArchived,
      activeNotification,
      notificationForCompare
} from "../../actions";
import PageTitle from "../../components/pageTitle";
import InputSearch from "../../components/inputs/input-search";
import moment from 'moment';
import axios from "axios";
import refreshToken from "../../api/refreshToken";
import LoaderComponent from "../musicManagement/components/loader";
import placeholder from "../../images/placeholder.png";
import SVGIcon from "../../components/svgIcon";
import Pagination from "../../components/pagination";
import debounce from "lodash.debounce";
import {url} from "../../api/api";

class NotificationsManagementIndex extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  search: '',
                  loading: false,

                  activeTab: 1,
                  tabType: 0,

                  notification: [],

                  page: 1,
                  formError: '',
            };

            this.onChangeDebounced = debounce(this.onChangeDebounced, 2000)
      }

      componentWillMount = () => {
            const {notificationType, notificationArchived} = this.props.notification;
            this.setState({
                  activeTab: notificationType,
                  tabType: notificationArchived,
            })
      };

      componentDidMount() {
            this.props.activeNotification({});
            this.props.notificationForCompare({});
            this.getData()
      };

      getData = () => {
            this.setState({
                  loading: true,
            });
            let token = localStorage.getItem('accessToken');
            let {page, search} = this.state;
            let path = `${url}/notifications`;

            if (search) {
                  path += `?include=semesters&type=${this.state.activeTab}&sort=createdAt%20desc&archived=${this.state.tabType}&term=${search}&page=${page}`;
            } else if (search && page === 1) {
                  path += `?include=semesters&type=${this.state.activeTab}&sort=createdAt%20desc&archived=${this.state.tabType}&term=${search}`;
            } else if (!search) {
                  path += `?include=semesters&type=${this.state.activeTab}&sort=createdAt%20desc&archived=${this.state.tabType}&page=${page}`;
            }

            axios.get(path,
                {
                      headers: {
                            Authorization: 'Bearer ' + token,
                      },
                })
                .then(response => {
                      // console.log(response.data);
                      this.setState({
                            loading: false,
                            notification: response.data.notifications,
                            total: response.data.meta.total,
                            perPage: response.data.meta.perPage,
                      })
                })
                .catch(error => {
                      // console.log(JSON.stringify(error));
                      this.setState({
                            loading: false,
                            formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                      });
                      if (error.response.status === 401) {
                            refreshToken(this.getData);
                      }
                });
      };

      archvedNotification = (id) => {
            let token = localStorage.getItem('accessToken');
            let path = '';
            if (this.state.tabType === 1) {
                  axios.get(`${url}/notifications/${id}/couldBeUnarchived`,
                      {
                            headers: {
                                  Authorization: 'Bearer ' + token,
                            },
                      })
                      .then(response => {
                            // console.log(response);
                            this.setState({
                                  loading: true,
                                  formError: '',
                            });
                            path = `${url}/notifications/${id}/unarchive`;
                            axios.post(path, {},
                                {
                                      headers: {
                                            Authorization: 'Bearer ' + token,
                                      },
                                })
                                .then(response => {
                                      // console.log(response);
                                      this.getData()
                                })
                                .catch(error => {
                                      // console.log(JSON.stringify(error));
                                      this.setState({
                                            loading: false,
                                            formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                                      });
                                      if (error.response.status === 401) {
                                            refreshToken(() => this.archvedNotification(id));
                                      }
                                });
                      })
                      .catch(error => {
                            // console.log(JSON.stringify(error));
                            this.setState({
                                  loading: false,
                                  formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                            });
                            if (error.response.status === 401) {
                                  refreshToken(() => this.archvedNotification(id));
                            }
                      });
            } else {
                  this.setState({
                        loading: true,
                  });
                  path = `${url}/notifications/${id}/archive`;
                  axios.post(path, {},
                      {
                            headers: {
                                  Authorization: 'Bearer ' + token,
                            },
                      })
                      .then(response => {
                            // console.log(response);
                            this.getData()
                      })
                      .catch(error => {
                            // console.log(JSON.stringify(error));
                            this.setState({
                                  loading: false,
                                  formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                            });
                            if (error.response.status === 401) {
                                  refreshToken(() => this.archvedNotification(id));
                            }
                      });
            }
      };

      search = (e) => {
            this.setState({
                  search: e.target.value,
                  counter: 0,
                  total: 0,
            });
            this.onChangeDebounced()
      };

      onChangeDebounced = () => {
            this.getData()
      };

      onChangePage = (page) => {
            this.setState(state => {
                  return {
                        ...state,
                        page: page,
                  }
            }, this.getData)
      };

      getNotificationData(id) {
            let token = localStorage.getItem('accessToken');
            axios.get(`${url}/notifications/${id}`,
                {
                      headers: {
                            Authorization: 'Bearer ' + token,
                      },
                })
                .then(response => {
                      // console.log(response);
                      this.props.activeNotification(response.data.notification);
                      this.props.notificationForCompare(response.data.notification);
                      this.props.history.push(`/notifications-management/${id}/edit-notification`);
                })
                .catch(error => {
                      // console.log(JSON.stringify(error));
                      this.setState({
                            loading: false,
                            formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                      });
                      if (error.response.status === 401) {
                            refreshToken(() => this.getNotificationData(id));
                      }
                });
      }

      renderContent = () => {
            const {loading, notification} = this.state;

            if (loading) {
                  return <tr>
                        <td colSpan="6"><LoaderComponent loading={loading}/></td>
                  </tr>
            } else if (notification.length === 0 && !loading) {
                  return <tr className='empty-state'>
                        <td colSpan="6">You don't have notifications</td>
                  </tr>
            } else if (notification.length > 0 && !loading) {
                  return notification.map((item, i) => {
                            return <tr key={i}>
                              <td class="trimmed-row">{item.title}</td>
                              <td class="trimmed-row">{item.text}</td>
                                  <td> {item.image ?
                                      <img src={item.image.path} alt=''
                                           className='collection-image' width='76'/> :
                                      <img src={placeholder} alt=''
                                           className='collection-image' width='76'/>}</td>
                                  <td>{item.semesters ? item.semesters.map((sem, i) =>
                                      <p key={i}>{sem.name}</p>) : 'No groups'}</td>
                                  {this.renderData(item)}
                                  <td>
                                        <div className='notif-actions'>
                                              <div
                                                  onClick={() => {
                                                        this.getNotificationData(item.id);
                                                        this.props.notificationMode('edit');
                                                        this.props.activeNotificationID(item.id);
                                                        this.props.notificationType(this.state.activeTab);
                                                  }}
                                              ><SVGIcon name='edit'
                                                        width='18'
                                                        className='icon'
                                              />
                                              </div>
                                              <div
                                                  onClick={() => this.archvedNotification(item.id)}
                                              >
                                                    <SVGIcon name='archived'
                                                             width='18'
                                                             className='icon'/>
                                              </div>
                                        </div>
                                  </td>
                            </tr>
                      }
                  )
            }
      };

      renderColumns = () => {
            if (this.state.activeTab === 1) {
                  return <td>date</td>
            } else {
                  return [<td key='td1'>student age</td>, <td key='td2'>time</td>]
            }
      };

      renderData = (item) => {
            if (this.state.activeTab === 1) {
                  let date = item.clause ? moment(item.clause.sendAt).format("M/DD/YYYY hh:mm A") : '-';
                  return <td>{date}</td>
            } else {
                  let yearsFrom = Math.floor(item.clause.ageFrom / 12);
                  let mothsFrom = item.clause.ageFrom % 12;
                  let from = `from ${yearsFrom} years and ${mothsFrom} month`;
                  let yearsTo = Math.floor(item.clause.ageTo / 12);
                  let mothsTo = item.clause.ageTo % 12;
                  let to = `to ${yearsTo} years and ${mothsTo} month`;
                  let time = moment(item.clause.sendAtTime, "HH:mm:ss").format('hh:mm A');
                  return [<td key='td1'>{from} {to}</td>, <td key='td2'>{time}</td>]
            }
      };

      clickTab = (tabType) => {
            this.setState({tabType: tabType, formError: ''}, this.getData);
            this.props.notificationArchived(tabType);
      };

      render() {
            const {search, activeTab, tabType, total, perPage, page} = this.state;
            return (
                <div className='page-container'>
                      <PageTitle title='Notifications management'/>
                      <div className='notifications-tabs-block'>
                            <p className={activeTab === 1 ? 'notif-tab active' : 'notif-tab'}
                               onClick={() => {
                                     this.setState({
                                           activeTab: 1,
                                           formError: ''
                                     }, this.getData);
                                     this.props.notificationType(2);

                               }}>Common</p>
                            <p className={activeTab === 2 ? 'notif-tab active' : 'notif-tab'}
                               onClick={() => {
                                     this.setState({
                                           activeTab: 2,
                                           formError: ''
                                     }, this.getData);
                                     this.props.notificationType(2);

                               }}>Childhood development</p>
                      </div>
                      <div className='top-content-block'>
                            <InputSearch placeholder='Search' value={search} onChange={(e) => this.search(e)}/>
                            <Link to={`/notifications-management/new-notification/id`} className='second-button'
                                  onClick={() => {
                                        this.props.notificationMode('new');
                                        this.props.notificationType(this.state.activeTab);
                                  }}>New notification</Link>
                      </div>
                      <p className={this.state.formError !== '' ? 'form-error' : 'form-error hide'}>
                            <SVGIcon name="error" width='16'
                                     fill='#ED1566'/><span>{this.state.formError}</span></p>
                      <div className='content-block notifications'>
                            <div className='content-header'>
                                  <p className='notif-title'>{tabType === 0 ? 'Active notifications' : 'Archived notifications'}</p>
                                  <div className='tabs-block'>
                                        <p className={tabType === 0 ? 'tab active ' : 'tab'}
                                           onClick={() => this.clickTab(0)}>Active</p>
                                        <p className={tabType === 1 ? 'tab active ' : 'tab'}
                                           onClick={() => this.clickTab(1)}>Archived</p>
                                  </div>
                            </div>
                            <table className='notification-table'>
                                  <thead>
                                  <tr>
                                        <td>Title</td>
                                        <td>text</td>
                                        <td>image</td>
                                        <td>student groups</td>
                                        {this.renderColumns()}
                                        <td>actions</td>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  {this.renderContent()}
                                  </tbody>
                            </table>
                            <div className='collection-music-footer'>
                                  <Pagination total={total}
                                              perPage={perPage}
                                              page={page}
                                              onChangePage={this.onChangePage}
                                  />
                            </div>
                      </div>
                </div>
            );
      }
}


const mapStateToProps = state => {
      return {
            notification: state.notification
      };
};

export default withRouter(connect(mapStateToProps, {
      notificationMode,
      activeNotificationID,
      notificationType,
      notificationArchived,
      activeNotification,
      notificationForCompare
})(NotificationsManagementIndex));

