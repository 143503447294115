import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import {importDataRoutes} from '../../routes/importDataRoutes';

class ImportData extends Component {
      render() {
            return (
                      <Switch>
                            {importDataRoutes.map((item, i) => { return (
                                <Route key={i} exact={item.exact} path={item.path} component={item.component}>{item.component}</Route>
                            )})}
                      </Switch>
            );
      }
}

export default ImportData;
