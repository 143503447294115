import React, {useState} from 'react';
import SVGIcon from '../svgIcon';


export default function InputPassword({
                                            label,
                                            value,
                                            placeholder,
                                            onChange,
                                            error,
                                            errorText
                                      }) {

      const [count, setCount] = useState(false);
      return <div className={error ? 'input-block' : 'input-block error'}>
            <p className='input-label'>{label}</p>
            <div className='input-wrapper'>
                  <input
                      name='userPassword'
                      type={count ? 'text' : 'password'}
                      placeholder={placeholder}
                      value={value}
                      onChange={onChange}
                      className={error ? 'input password' : 'input password error'}
                  />
                  <div onClick={() => setCount(!count)} className={count ? 'password-button' : 'password-button1'}>{count ?
                      <SVGIcon name="close-eye" width='16' fill='#2D3E56'/> :
                      <SVGIcon name="eye" width='16' fill='#2D3E56'/>
                  }</div>

            </div>
            <p className='input-error'><SVGIcon name="error" width='16' fill='#ED1566'/><span>{errorText}</span></p>
      </div>
}
