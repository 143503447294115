import ImportDataUpload from "../pages/import/importData-upload";
import ImportDataIndex from "../pages/import/importData-index";
import ImportDataSuccess from "../pages/import/importData-success";
import React from "react";

export const importDataRoutes =  [
      {
            path: '/import-data',
            exact: true,
            component: ImportDataIndex
      },
      {
            path: '/import-data/uploading',
            component: ImportDataUpload
      },
      {
            path: '/import-data/success',
            component: ImportDataSuccess
      }
    ];
