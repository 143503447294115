import React, {useState} from 'react';
import SVGIcon from '../../../components/svgIcon';
import InputText from "../../../components/inputs/input-text";
import LoaderComponent from "./loader";
import ModalCropImage from "./modalForCrop";


export default function TrackComponent({item, deleteImage, addImage, onChangeName, deleteTrack, localId}) {
      const [width, setWidth] = useState(0);
      const [error, setError] = useState('');
      const [modalCrop, setModalCrop] = useState('');
      const [file, setFile] = useState('');

      function stateLoading() {
            if (item.stateLoading === 'inProgress') {
                  return <LoaderComponent loading={item.stateLoading === 'inProgress'}/>
            } else if (item.stateLoading === 'done') {
                  return <div style={{padding: '10px'}}><SVGIcon name="success" width='30' height="30" fill='#03BAAE'/>
                  </div>
            }
      };

      function renderFiles() {
            return (
                <div className='track-files-block'>
                      {item.mp3 &&
                      <div className='file-wrapper'>
                            <SVGIcon
                                name='emptyFile'
                                width='20'
                                height='25'
                                fill='#666666'/>
                            <span className='file-name'>{item.name + '.mp3'}</span>
                      </div>
                      }
                      {item.hasLyrics &&
                      <div className='file-wrapper'><SVGIcon
                          name='emptyFile'
                          width='20'
                          height='25'
                          fill='#666666'/>
                            <span className='file-name'>{item.name} (lyrics)</span>
                      </div>
                      }
                </div>
            )
      }

      function onImgLoad({target: img}) {
            setError('');
            setWidth(img.offsetWidth);
            if (img.offsetWidth !== img.offsetHeight) {
                  setError('The image should be square.');
                  deleteImage();
            }
      }

      function imageRender() {
            let value = item.image;
            if (value !== null && value !== undefined) {
                  if (item.imageId === null) {
                        let img = new Image();
                        img.onload = onImgLoad;
                        return <img onLoad={onImgLoad} src={URL.createObjectURL(value)} alt=''
                                    className={error === '' ? 'track-image' : 'track-image hide'}/>
                  } else if (value.path) {
                        return <img src={value.path} alt=''
                                    className='track-image' />
                  }
            }
      }


      return (
          <div className='track-line'>
                {renderFiles()}
                <div className='image-button-wrapper'>
                      {error && <span>{error}</span>}
                      <label htmlFor={'single-file' + localId}>
                            <div className='image-button'>
                                  <SVGIcon
                                      name="camera"
                                      width='24'
                                      height='24'
                                      fill='#03BAAE'/>
                            </div>
                      </label>
                      <input type='file' accept="image/*" id={'single-file' + localId} onChange={(e) => {setModalCrop(true); setFile(e.target.files[0]); e.target.value = ''}}/>
                      {imageRender()}
                      {item.image &&
                      <div className={width > 0 && error === '' ? 'delete-image' : 'delete-image hide'} onClick={deleteImage}><SVGIcon name="error"
                                                                                   width='20'
                                                                                   height='20'
                                                                                   fill='#000'/></div>}
                </div>
                <div className='track-name-block'>
                      <InputText label='title*' error={true} value={item.name}
                                 onChange={onChangeName}/>
                </div>
                <div className='actions-block'>
                      <p className='track-actions-title'>ACTIONS</p>
                      <div onClick={deleteTrack} className='delete-track-block'>
                            <SVGIcon name='trash'
                                     width='14'
                                     height='18'
                                     className='icon'/>
                      </div>
                </div>
                <div style={{marginTop: '28px', width: '50px'}}>
                      {stateLoading()}
                </div>
                {modalCrop && <ModalCropImage
                    file={file}
                    onPush={(croppedImage) => {
                          setModalCrop(false);
                          addImage(croppedImage)
                    }}
                    closeModal={() => setModalCrop(false)}
                />}
          </div>
      )
}

