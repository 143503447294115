import { combineReducers } from 'redux';

import AuthReducer from './auth';
import ImportReducer from './importData';
import MusicReducer from './music';
import NotificationReducer from './notifications';

export default combineReducers({
    auth: AuthReducer,
    files: ImportReducer,
    music: MusicReducer,
    notification: NotificationReducer,
})
