import React from 'react';
import SVGIcon from './svgIcon';

export default function Pagination({
                                         total,
                                         perPage,
                                         page,
                                         onChangePage
                                   }) {

      function renderItem(page, isCurrent) {
            return <button
                key={page}
                className={'pagination-number' + (isCurrent ? ' active' : '')}
                onClick={() => {
                      if (!isCurrent) {
                            onChangePage(page)
                      }
                }}>
                  {page}
            </button>
      }
      const pagesCount = Math.ceil(total / perPage);
      const items = [];

      for (let i = 1; i <= pagesCount; i++) {
            if (i === page) {
                  items.push(renderItem(i, true))
            } else {
                  items.push(renderItem(i))
            }
      }

      if (pagesCount > 1) {
            return <div className="pagination-line">
                  { page > 1 && <button
                      className="pagination-button"
                      onClick={() => onChangePage(page - 1)}
                      disabled={page === 1}
                  >
                        <SVGIcon name="chevron-left" width='7' fill='#2D3E56'/>
                  </button>}
                  {items}
                  { page < pagesCount && <button
                      className="pagination-button"
                      onClick={() => onChangePage(page + 1)}
                      disabled={total / (page * perPage) <= 1}
                  >
                        <SVGIcon name="chevron-right" width='7' fill='#2D3E56'/>
                  </button> }
            </div>
      } else {
            return null
      }
}
