import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import PageTitle from "../../components/pageTitle";
import InputText from "../../components/inputs/input-text";
import InputEmail from "../../components/inputs/input-email";
import InputPassword from "../../components/inputs/input-password";
import axios from "axios";
import refreshToken from "../../api/refreshToken";
import SVGIcon from "../../components/svgIcon";
import LoaderComponent from "../musicManagement/components/loader";
import {url} from "../../api/api";
import LinksComponent from "./components/linksBlock";

class InfoPage extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  loading: false,
                  links: [],

                  formError: '',
            }
      }

      componentDidMount() {
            this.getLinks();
      }

      getLinks = () => {
            this.setState({
                  loading: true,
            });
            let token = localStorage.getItem('accessToken');

            axios.get(`${url}/links`,
                {
                      headers: {
                            Authorization: 'Bearer ' + token,
                      },
                })
                .then(response => {
                      // console.log(response);
                      let links = response.data.links;
                      if (links.length > 0) {
                            links.forEach((link) => {
                                  link['errorLink'] = '';
                                  link['errorName'] = '';
                                  link['error'] = '';
                                }
                            )
                      }
                      while (links.length < 5) {
                            links.push({
                                  name: '',
                                  url: '',
                                  errorName: '',
                                  errorLink: '',
                                  error: '',
                            })
                      }
                      this.setState({
                            links: links,
                            loading: false,
                            formError: '',
                      });
                })
                .catch(error => {
                      // console.log(JSON.stringify(error.response));
                      this.setState({
                            loading: false,
                            formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                      });
                      if (error.response.status === 401) {
                            refreshToken(this.getLinks);
                      }
                });
      };

      saveUpdates = () => {
            let linksArray = this.state.links;

            linksArray.forEach((item, i) => {
                  if (item.id && item.changes) {
                        let body = {
                              name: item.name,
                              url: item.url
                        };
                        this.editLink(item.id, body, i)
                  } else if (!item.id && item.name && item.url) {
                        let body = {
                              name: item.name,
                              url: item.url
                        };
                        this.createLink(body, i)
                  } else if (!item.id && (!item.name || !item.url)) {
                        this.setState({
                              formError: 'You should fill fields with errors',
                        });
                  }
            });
      };

      createLink = (body, i) => {
            this.setState({
                  loading: true,
            });
            let token = localStorage.getItem('accessToken');

            axios.post(`${url}/links`,
                body,
                {
                      headers: {
                            Authorization: 'Bearer ' + token,
                      },
                })
                .then(response => {
                      console.log(response);
                      let links = this.state.links;
                      links[i]['error'] = '';
                      links[i]['changes'] = false;
                      links[i]['id'] = response.data.link.id;
                      this.setState({
                            links: links,
                            loading: false,
                      })
                })
                .catch(error => {
                      // console.log(JSON.stringify(error.response));
                      let links = this.state.links;
                      let errorArray = Object.values(error.response.data.fields);
                      let errorString = '';

                      errorArray.forEach((item, i) => {
                            errorString += item;
                      });
                      links[i]['error'] = errorString;
                      this.setState({
                            links: links,
                            loading: false,
                            formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                      });
                      if (error.response.status === 401) {
                            refreshToken(this.saveUpdates);
                      } else if (error.response.status === 422) {
                            this.setState({
                                  formError: error.response.data.fields.url,
                            });
                      }
                });
      };

      editLink = (id, body, i) => {
            this.setState({
                  loading: true,
            });
            let token = localStorage.getItem('accessToken');

            axios.put(`${url}/links/${id}`,
                body,
                {
                      headers: {
                            Authorization: 'Bearer ' + token,
                      },
                })
                .then(response => {
                      console.log(response);
                      let links = this.state.links;
                      links[i]['error'] = '';
                      links[i]['changes'] = false;
                      this.setState({
                            links: links,
                            loading: false,
                      })
                })
                .catch(error => {
                      // console.log(JSON.stringify(error.response));
                      let links = this.state.links;
                      let errorArray = Object.values(error.response.data.fields);
                      let errorString = '';

                      errorArray.forEach((item, i) => {
                            errorString += item;
                      });
                      links[i]['error'] = errorString;
                      this.setState({
                            links: links,
                            loading: false,
                            formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                      });
                      if (error.response.status === 401) {
                            refreshToken(() => this.editLink(id, body));
                      }
                });
      };

      deleteLink = (item, index) => {
            let token = localStorage.getItem('accessToken');

            if (item.id) {
                  axios.delete(`${url}/links/${item.id}`,
                      {
                            headers: {
                                  Authorization: 'Bearer ' + token,
                            },
                      })
                      .then(response => {
                            // console.log(response);
                            this.getLinks()
                      })
                      .catch(error => {
                            // console.log(JSON.stringify(error.response));
                            this.setState({
                                  loading: false,
                                  formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                            });
                            if (error.response.status === 401) {
                                  refreshToken(this.deleteLink);
                            }
                      });
            } else {
                  let links = this.state.links;
                  links[index]['name'] = '';
                  links[index]['url'] = '';
                  links[index].changes = false;
                  this.setState({links})
            }
      };

      _handleUserInput = (name, event, index) => {
            let value = event.target.value;
            let links = this.state.links;
            if (event.target.value.charAt(0) === ' ') {
                  return;
            }
            if (value.length <= 255) {
                  links[index][name] = value;
                  links[index].changes = true;
            }

            if (name === 'name') {
                  if (value.length === 0 && links[index].url.length === 0) {
                        links[index]['errorLink'] = '';
                        links[index]['errorName'] = '';
                  } else if (value.length > 0 && links[index].url.length === 0) {
                        links[index]['errorLink'] = 'Please add the link';
                  } else if (value.length === 0 && links[index].url.length > 0) {
                        links[index]['errorName'] = 'Please add the name for the link';
                  } else {
                        links[index]['errorLink'] = '';
                        links[index]['errorName'] = '';
                  }
            }

            if (name === 'url') {
                  if (value.length === 0 && links[index].name.length === 0) {
                        links[index]['errorLink'] = '';
                        links[index]['errorName'] = '';
                  } else if (value.length > 0 && links[index].name.length === 0) {
                        links[index]['errorName'] = 'Please add the name for the link';
                  } else if (value.length === 0 && links[index].name.length > 0) {
                        links[index]['errorLink'] = 'Please add the link';
                  } else {
                        links[index]['errorLink'] = '';
                        links[index]['errorName'] = '';
                  }
            }

            this.setState({links, changes: true,})


      };

      render() {
            return (
                <Switch>
                      <Route key='info-page' path='/info-page-management'>
                            <div className='page-container'>
                                  <PageTitle title='Info page management '/>
                                  <div className='info-wrapper'>
                                        <p className='info-text'>Please put links with its name to the fields. This data
                                              will be shown on the Info
                                              page in the application. The maximum amount of the links is 5, the minimum
                                              is 1.
                                              The buttons will be dynamically added in the app in the same queue when
                                              you add
                                              them here. Please be careful. After you save the changes, they will be
                                              displayed
                                              in the application
                                        </p>
                                        {this.state.loading &&
                                        <LoaderComponent loading={this.state.loading}/>}
                                        <div className='links-wrapper'>
                                              {(this.state.links.length > 0 && !this.state.loading) && this.state.links.map((link, i) => {
                                                    return <LinksComponent key={i} item={link}
                                                                           onChange={(name, event, number) => this._handleUserInput(name, event, number)}
                                                                           number={i}
                                                                           deleteLink={() => this.deleteLink(link, i)}/>
                                              })}
                                        </div>
                                        <div className='content-footer'>
                                              <div>
                                                    <button className='fourth-button'
                                                            onClick={() => this.getLinks()}>Cancel
                                                    </button>
                                                    <button
                                                        className={this.state.changes ? 'first-button' : 'first-button disabled'}
                                                        onClick={this.saveUpdates}>Save
                                                    </button>
                                              </div>
                                        </div>
                                  </div>
                            </div>
                      </Route>
                </Switch>
            );
      }
}

export default InfoPage;
