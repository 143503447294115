import React, {useState} from 'react';
import SVGIcon from '../components/svgIcon';
import {
      useLocation, useRouteMatch
} from "react-router-dom";
import axios from 'axios';
import customHistory from "../pages/history";
import refreshToken from "../api/refreshToken";
import ModalLeavePage from "../pages/musicManagement/components/modalForLeave";
import ModalForLogout from "./modalForLogout";
import {url} from '../api/api';

export default function TopBar({}) {
      const [modal, setModal] = useState(false);
      const [modalLogOut, setModalLogOut] = useState(false);
      const [route, setRoute] = useState('/music-management');
      let location = useLocation();
      const user = JSON.parse(localStorage.getItem('user'));

      function logOut() {
            const token = localStorage.getItem('accessToken');
            axios.delete(`${url}/auth/revoke`, {
                  headers: {
                        Authorization: 'Bearer ' + token,
                  },
            })
                .then(() => {
                      localStorage.removeItem('accessToken');
                      localStorage.removeItem('refreshToken');
                      customHistory.push('/login');
                })
                .catch((error) => {
                      localStorage.removeItem('accessToken');
                      localStorage.removeItem('refreshToken');
                      customHistory.push('/login');
                      if (error.response.status === 401) {
                            refreshToken(logOut);
                      }
                })
      }

      function click(route) {
            if (location.pathname !== '/import-data' && location.pathname !== '/notifications-management' && location.pathname !== '/students-management' && location.pathname !== '/music-management') {
                  setModal(true);
                  setRoute(route);
            } else {
                  customHistory.push(route)
            }
      }

      return <div className={ useRouteMatch('/user-profile') ? 'top-bar active' : 'top-bar'}>
            <div onClick={() => click('/user-profile')} className='user-name-block'>
                  <div className='block-like-icon'></div>
                  <span>{user.name ? user.name : 'user name'}</span>
            </div>
            <div onClick={() => setModalLogOut(true)} className='logout-button'>
                  <SVGIcon name='exit' width='24' fill='#2D3E56'/>
            </div>
            {modal && <ModalLeavePage
                leave={() => {customHistory.push(route); setModal(false)}}
                no={() => {
                      setModal(false);
                }}/>}
            {modalLogOut && <ModalForLogout
                onPush={() => {logOut(); setModalLogOut(false)}}
                closeModal={() => {
                      setModalLogOut(false);
                }}/>}
      </div>
}
