import React, {Component} from 'react';
import PageTitle from "../../components/pageTitle";
import SVGIcon from "../../components/svgIcon";
import {connect} from "react-redux";
import {importFiles} from "../../actions";
import {withRouter} from 'react-router-dom';

class MusicManagementSuccess extends Component {
      componentDidMount() {
            setTimeout( () => {this.props.history.push('/music-management')},5000)
      }
      render() {
            let {collectionTitle} = this.props.music;
            let collectionName = collectionTitle.length > 20 ? (collectionTitle).substring(0, 20) + '...' : collectionTitle;

            return (
                <div className='page-container'>
                      <PageTitle title={this.props.music.mode === 'edit' ? `Edit collection - ${collectionName}` : `New collection - ${collectionName}`} backButton={false} history={this.props.history}/>
                      <div className='content-block import-page success-page'>
                            <div className='content-title'>
                                  Success
                            </div>
                            <p className='success-text'>{this.props.music.mode === 'edit' ? 'All the changes in the collection were successfully uploaded' : 'The collection was successfully created'}</p>
                            <SVGIcon name='success' width='56' height='56' fill='#03BAAE'/>
                      </div>
                </div>
            );
      }
}


const mapStateToProps = state => {
      return {
            files: state.files,
            music: state.music,
      };
};

export default withRouter(connect(mapStateToProps, {importFiles})(MusicManagementSuccess));
