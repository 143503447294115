import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import PageTitle from "../../components/pageTitle";
import InputText from "../../components/inputs/input-text";
import InputEmail from "../../components/inputs/input-email";
import InputPassword from "../../components/inputs/input-password";
import axios from "axios";
import refreshToken from "../../api/refreshToken";
import SVGIcon from "../../components/svgIcon";
import LoaderComponent from "../musicManagement/components/loader";
import {url} from "../../api/api";

class UserProfile extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  loading: false,

                  name: '',
                  nameError: '',
                  nameValid: true,

                  email: '',
                  emailError: '',
                  emailValid: true,

                  currentPassword: '',
                  currentPasswordError: '',
                  currentPasswordValid: true,

                  newPassword: '',
                  newPasswordError: '',
                  newPasswordValid: true,

                  repeatNewPassword: '',
                  repeatNewPasswordError: '',
                  repeatNewPasswordValid: true,

                  formError: '',
            }
      }

      componentDidMount() {
            let user = JSON.parse(localStorage.getItem('user'));
            this.setState({
                  email: user.email,
                  name: user.name,
            })
      }

      getUserData = () => {
            let user = JSON.parse(localStorage.getItem('user'));

            let token = localStorage.getItem('accessToken');
            axios.get(`${url}/admins/${user.id}`,
                {
                      headers: {
                            Authorization: 'Bearer ' + token,
                      },
                })
                .then(response => {
                      // console.log(response);
                      this.setState({
                            loading: false,
                            email: response.data.admin.email,
                            name: response.data.admin.name,
                            changes: false,
                            formError: '',
                      });
                      localStorage.setItem('user', JSON.stringify(response.data.admin));
                })
                .catch(error => {
                      // console.log(JSON.stringify(error.response));
                      this.setState({
                            loading: false,
                            formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                      });
                      if (error.response.status === 401) {
                            refreshToken(this.getUserData);
                      }
                });
      };

      saveUpdates = () => {
            this.setState({
                  loading: true,
            });
            let token = localStorage.getItem('accessToken');
            let user = JSON.parse(localStorage.getItem('user'));
            const body = {};
            body['name'] = this.state.name;
            body['email'] = this.state.email;

            if (this.state.repeatNewPassword !== '') {
                  body['currentPassword'] = this.state.currentPassword;
                  body['password'] = this.state.repeatNewPassword;
            }

            axios.put(`${url}/admins/${user.id}`,
                body,
                {
                      headers: {
                            Authorization: 'Bearer ' + token,
                      },
                })
                .then(response => {
                      // console.log(response);
                      this.setState({
                            loading: false,
                            currentPassword: '',
                            newPassword: '',
                            repeatNewPassword: '',
                            formError: '',
                      });
                      this.getUserData()
                })
                .catch(error => {
                      // console.log(JSON.stringify(error.response));
                      this.setState({
                            loading: false,
                            formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                      });
                      if (error.response.status === 401) {
                            refreshToken(this.saveUpdates);
                      }
                });
      };

      _handleUserInput = (name, event) => {
            let title = name;
            let value = event.target.value;
            if (event.target.value.charAt(0) === ' ') {
                  return;
            }
            this.setState({[title]: value, formError: '', changes: true},
                () => {
                      this._validateField(title, value);
                });
      };

      _validateField(fieldName, value) {
            let nameError = this.state.nameError;
            let emailError = this.state.emailError;
            let currentPasswordError = this.state.currentPasswordError;
            let newPasswordError = this.state.newPasswordError;
            let repeatNewPasswordError = this.state.repeatNewPasswordError;

            let nameValid = this.state.nameValid;
            let emailValid = this.state.emailValid;
            let currentPasswordValid = this.state.currentPasswordValid;
            let newPasswordValid = this.state.newPasswordValid;
            let repeatNewPasswordValid = this.state.repeatNewPasswordValid;

            switch (fieldName) {
                  case 'name':
                        nameValid = value.length > 0;
                        nameError = nameValid ? '' : 'This field is required';
                        break;
                  case 'email':
                        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                        emailError = emailValid ? '' : 'Email is not correct';
                        break;
                  case 'currentPassword':
                        currentPasswordValid = value.length > 0;
                        currentPasswordError = currentPasswordValid ? '' : 'This field is required';
                        break;
                  case 'newPassword':
                        newPasswordValid = value.match('^(((?=.*[a-z])(?=.*[A-Z]))(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>\\-\\;\\/\\\\\'\\[\\]\\+\\=\\_\\\\\`\\~\\|\\•\\√\\π\\÷\\×\\¶\\∆\\}\\{\\=\\°\\¥\\€\\¢\\£\\%\\©\\®\\™\\✓\\[\\]]))(?=.{6,})');
                        if (newPasswordValid && value !== this.state.repeatNewPassword) {
                              newPasswordError = 'Password is not the same';
                              repeatNewPasswordValid = value === this.state.repeatNewPassword;
                              repeatNewPasswordError = repeatNewPasswordValid ? '' : 'Password is not the same';
                        } else if (!newPasswordValid) {
                              newPasswordError = 'The password is required 6 symbols with at least 1 uppercase and 1 lowercase letter, 1 number and 1 special character';
                              repeatNewPasswordValid = value === this.state.repeatNewPassword;
                              repeatNewPasswordError = repeatNewPasswordValid ? '' : 'Password is not the same';
                        } else if (value !== this.state.repeatNewPassword) {
                              newPasswordError = 'Password is not the same';
                              repeatNewPasswordValid = value === this.state.repeatNewPassword;
                              repeatNewPasswordError = 'Password is not the same';
                        } else {
                              newPasswordError = '';
                              repeatNewPasswordValid = value === this.state.repeatNewPassword;
                              repeatNewPasswordError = '';
                        }
                        break;
                  case 'repeatNewPassword':
                        repeatNewPasswordValid = value === this.state.newPassword;
                        repeatNewPasswordError = repeatNewPasswordValid ? '' : 'Password is not the same';
                        newPasswordError = repeatNewPasswordValid ? '' : 'Password is not the same';
                        break;
                  default:
                        break;
            }

            this.setState({
                  nameError: nameError,
                  emailError: emailError,
                  currentPasswordError: currentPasswordError,
                  newPasswordError: newPasswordError,
                  repeatNewPasswordError: repeatNewPasswordError,

                  nameValid: nameValid,
                  emailValid: emailValid,
                  currentPasswordValid: currentPasswordValid,
                  newPasswordValid: newPasswordValid,
                  repeatNewPasswordValid: repeatNewPasswordValid,
            });
      };

      cancelChanges = () => {
            let user = JSON.parse(localStorage.getItem('user'));
            this.setState({
                  name: user.name,
                  email: user.email,
                  currentPassword: '',
                  newPassword: '',
                  repeatNewPassword: '',
            })
      };

      render() {
            return (
                <Switch>
                      <Route key='user-route' path='/user-profile'>
                            <div className='page-container'>
                                  <PageTitle title='Profile '/>
                                  {this.state.loading &&
                                  <LoaderComponent loading={this.state.loading}/>}
                                  <div className='user-profile-block'>
                                        <InputText
                                            label='admin name'
                                            placeholder='Admin name'
                                            value={this.state.name}
                                            onChange={event => this._handleUserInput('name', event)}
                                            defaultValue={this.state.name}
                                            name='name'
                                            error={this.state.nameValid}
                                            errorText={this.state.nameError}/>
                                        <InputEmail
                                            label='email'
                                            placeholder='Email'
                                            value={this.state.email}
                                            onChange={event => this._handleUserInput('email', event)}
                                            defaultValue={this.state.email}
                                            name='email'
                                            error={this.state.emailValid}
                                            errorText={this.state.emailError}
                                        />
                                        <div className='user-profile-line'></div>
                                        <InputPassword
                                            label='old password*'
                                            placeholder='Old password'
                                            value={this.state.currentPassword}
                                            onChange={event => this._handleUserInput('currentPassword', event)}
                                            defaultValue={this.state.currentPassword}
                                            name='currentPassword'
                                            error={this.state.currentPasswordValid}
                                            errorText={this.state.currentPasswordError}
                                        />
                                        <InputPassword
                                            label='new password'
                                            placeholder='New password'
                                            value={this.state.newPassword}
                                            onChange={event => this._handleUserInput('newPassword', event)}
                                            defaultValue={this.state.newPassword}
                                            name='newPassword'
                                            error={this.state.newPasswordError === ''}
                                            errorText={this.state.newPasswordError}
                                        />
                                        <InputPassword
                                            label='repeat password'
                                            placeholder='Repeat password'
                                            value={this.state.repeatNewPassword}
                                            onChange={event => this._handleUserInput('repeatNewPassword', event)}
                                            defaultValue={this.state.repeatNewPassword}
                                            name='repeatNewPassword'
                                            error={this.state.repeatNewPasswordValid}
                                            errorText={this.state.repeatNewPasswordError}
                                        />
                                        <p className={this.state.formError !== '' ? 'form-error' : 'form-error hide'}>
                                              <SVGIcon name="error" width='16'
                                                       fill='#ED1566'/><span>{this.state.formError}</span></p>
                                        <div className='content-footer'>
                                              <div>
                                                    <button className='fourth-button'
                                                            onClick={() => this.cancelChanges()}>Cancel
                                                    </button>
                                                    <button
                                                        className={this.state.changes  ? 'first-button' : 'first-button disabled'}
                                                        onClick={this.saveUpdates}>Save
                                                    </button>
                                              </div>
                                        </div>
                                  </div>
                            </div>
                      </Route>
                </Switch>
            );
      }
}

export default UserProfile;
