import React from 'react';
import SVGIcon from '../../components/svgIcon';


export default function InputSearch({
                                          value,
                                          placeholder,
                                          onChange,
                                    }) {

      return <div>
            <div className='input-wrapper'>
                  <div className='input-search-icon'>
                        <SVGIcon name="search" width='18' fill='#C0CBDE'/>
                  </div>
                  <input
                      name='userPassword'
                      type={'text'}
                      placeholder={placeholder}
                      value={value}
                      onChange={onChange}
                      className='input-search'
                  />
            </div>
      </div>
}
