import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import {musicRoutes} from '../../routes/musicRoutes';

class MusicManagement extends Component {
      render() {
            return (
                <Switch>
                      {musicRoutes.map((item, i) => { return (
                          <Route key={i} exact={item.exact} path={item.path} component={item.component}>{item.component}</Route>
                      )})}
                </Switch>
            );
      }
}

export default MusicManagement;
