import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from "react-redux";
import {collectionMode, collectionFiles, activeCollection} from "../../actions";
import PageTitle from "../../components/pageTitle";
import InputSearch from "../../components/inputs/input-search";
import MusicCollection from "../musicManagement/components/collection";
import ModalDeleteCollection from './components/modalForDelete';
import axios from "axios";
import refreshToken from "../../api/refreshToken";
import LoaderComponent from "./components/loader";
import Pagination from "../../components/pagination";
import debounce from 'lodash.debounce';
import SVGIcon from "../../components/svgIcon";
import {url} from '../../api/api';

class MusicManagementIndex extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  search: '',
                  loading: false,
                  modal: false,
                  activeId: 0,

                  collections: [],
                  counter: 0,

                  total: 0,
                  perPage: 0,
                  page: 1,

                  formError: '',
            };

            this.onChangeDebounced = debounce(this.onChangeDebounced, 2000)
      }

      componentDidMount() {
            this.props.collectionFiles([]);
            this.props.activeCollection({});
            this.getData();
      }

      getData = () => {
            this.setState({
                  loading: true,
            });
            let token = localStorage.getItem('accessToken');
            let {page, search} = this.state;
            let path = `${url}/collections`;

            if (search) {
                  path += '/search?term=' + search + '&page=' + page + '&sort=createdAt%20desc';
            } else if (search && page === 1) {
                  path += '/search?term=' + search + '&sort=createdAt%20desc';
            } else {
                  path += '?page=' + page + '&sort=createdAt%20desc';
            }

            axios.get(path,
                {
                      headers: {
                            Authorization: 'Bearer ' + token,
                      },
                })
                .then(response => {
                      // console.log(response.data);
                      this.setState({
                            loading: false,
                            collections: response.data.collections,
                            total: response.data.meta.total,
                            perPage: response.data.meta.perPage,
                            formError: '',
                      });
                })
                .catch(error => {
                      // console.log(JSON.stringify(error));
                      this.setState({
                            loading: false,
                            formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                      });
                      if (error.response.status === 401) {
                            refreshToken(this.getData);
                      }
                });
      };

      deleteCollection = () => {
            this.setState({
                  loading: true,
                  collections: [],
            });
            const token = localStorage.getItem('accessToken');
            axios.delete(`${url}/collections/${this.state.activeId}`, {
                  headers: {
                        Authorization: 'Bearer ' + token,
                  },
            })
                .then((response) => {
                      // console.log(response);
                      this.setState({modal: false, loading: false,  formError: '',});
                      this.getData();
                })
                .catch((error) => {
                      // console.log(error);
                      this.setState({
                            modal: false,
                            loading: false,
                            formError: error.response.data.message ? error.response.data.message : 'Looks like something went wrong. Please try again later.',
                      });
                      if (error.response.status === 401) {
                            refreshToken(this.deleteCollection);
                      }
                });
      };

      search = (e) => {
            this.setState({
                  search: e.target.value,
                  counter: 0,
                  total: 0,
            });
            this.onChangeDebounced()
      };

      onChangeDebounced = () => {
            this.getData()
      };

      renderContent = () => {
            const {search, loading, collections} = this.state;

            if (loading) {
                  return <LoaderComponent loading={loading}/>
            } else if (collections.length === 0 && !loading && search) {
                  return <div className='empty-state'><p>There are no results for your search</p></div>
            } else if (collections.length === 0 && !loading) {
                  return <div className='empty-state'><p>You don't have collections</p></div>
            } else if (collections.length > 0 && !loading) {
                  return collections.map((item, i) => (
                      <MusicCollection
                          item={item} key={i}
                          chooseCollection={(id) => {
                                this.setState({activeId: id, modal: true})
                          }}
                          search={search}/>
                  ))
            }
      };

      onChangePage = (page) => {
            this.setState(state => {
                  return {
                        ...state,
                        page: page,
                  }
            }, this.getData)
      };

      render() {
            const {search, loading} = this.state;
            return (
                <div className='page-container'>
                      <PageTitle title='Music management'/>
                      <div className='top-content-block'>
                            <InputSearch placeholder='Search' value={search} onChange={(e) => this.search(e)}/>
                            <Link to={`/music-management/new-collection/id`} className='second-button' onClick={() => {
                                  this.props.collectionMode('new')
                            }}>New collection</Link>
                      </div>
                      <p className={this.state.formError !== '' ? 'form-error' : 'form-error hide'}>
                            <SVGIcon name="error" width='16' height='16'
                                     fill='#ED1566'/><span>{this.state.formError}</span></p>
                      {this.renderContent()}
                      {this.state.modal &&
                      <ModalDeleteCollection loading={loading} id={this.state.id} onPush={() => this.deleteCollection()}
                                             closeModal={() => this.setState({modal: false})}/>}
                      <div className='collections-footer'>
                            <Pagination total={this.state.total}
                                        perPage={this.state.perPage}
                                        page={this.state.page}
                                        onChangePage={this.onChangePage}
                            />
                      </div>
                </div>
            );
      }
}

const mapStateToProps = state => {
      return {
            files: state.files
      };
};

export default withRouter(connect(mapStateToProps, {
      collectionMode,
      collectionFiles,
      activeCollection
})(MusicManagementIndex));

