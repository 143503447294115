import React from 'react';
import { Redirect } from 'react-router-dom';
import LoginPage from './pages/loginPage';
import MusicManagement from './pages/musicManagement/musicManagement';
import StudentsManagement from './pages/students/studentsManagement';
import NotificationsManagement from './pages/notifications/notificationsManagement';
import ImportData from './pages/import/importData';
import { FirstLayout, DefaultLayout as DefaultLayoutOriginal } from './layouts';
import { withRouter } from 'react-router'
import UserProfile from "./pages/userProfile/userProfile";
import InfoPage from "./pages/info/infoPage";
const DefaultLayout = withRouter(DefaultLayoutOriginal)

const routes =  [
    {
        path: '/',
        exact: true,
        layout: DefaultLayout,
        component: () => <Redirect to="/music-management" />
    },
    {
        path: '/login',
        exact: true,
        layout: FirstLayout,
        component: LoginPage
    },
    {
        path: '/user-profile',
        exact: true,
        layout: DefaultLayout,
        component: UserProfile,
    },
    {
        path: '/music-management',
        layout: DefaultLayout,
        component: MusicManagement
    },
    {
        path: '/students-management',
        layout: DefaultLayout,
        component: StudentsManagement
    },
    {
        path: '/notifications-management',
        layout: DefaultLayout,
        component: NotificationsManagement
    },
    {
        path: '/import-data',
        layout: DefaultLayout,
        component: ImportData
    },
    {
        path: '/info-page-management',
        layout: DefaultLayout,
        component: InfoPage
    },
]

export default routes;
