import NotificationsManagementIndex from "../pages/notifications/notificationsManagement-index";
import NotificationsManagementStep1 from "../pages/notifications/notificationsManagement-step1";
import NotificationsManagementStep2 from "../pages/notifications/notificationsManagement-step2";
import NotificationsManagementSuccess from "../pages/notifications/notificationsManagement-success";


export const notificationsRoutes = [
      {
            path: '/notifications-management',
            exact: true,
            component: NotificationsManagementIndex,
      },
      {
            path: '/notifications-management/new-notification/id',
            component: NotificationsManagementStep1,
      },
      {
            path: '/notifications-management/:notificationId/edit-notification',
            component: NotificationsManagementStep1,
      },
      {
            path: 'notifications-management/new-notification/rules',
            component: NotificationsManagementStep2,
      },
      {
            path: '/notifications-management/:notificationId/rules',
            component: NotificationsManagementStep2,
      },
      {
            path: '/notifications-management/new-notification/success',
            component: NotificationsManagementSuccess,
      },
      {
            path: '/notifications-management/:notificationId/success',
            component: NotificationsManagementSuccess,
      },
];
