import React, {Component} from 'react';
import Logo from '../images/logo.png';
import customHistory from "./history";

class ErrorPage extends Component {
    render() {
        return (
            <div className='error-page'>
                <div onClick={() => customHistory.push('/music-management')} className='error-image-link'>
                    <img src={Logo} alt='' className='error-image' width='172'/>
                </div>
                <p className='error-number'>404</p>
                <p className='error-title'>Page not found</p>
                <p className='error-text'>Page you are looking for doesn’t exist</p>
                <div className='first-button' onClick={() => customHistory.goBack()}>Back</div>
            </div>
        );
    }
}

export default ErrorPage;
